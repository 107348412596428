import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
  
function Homeslider() {
  const [showModal, setShowModal] = useState(true);
  const [selectedOption, setSelectedOption] = useState("limited");
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const Navigate = useNavigate();
  const handleCloseModal = () => {
    Navigate("/managehomeslider");
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 50 * 1024; // 100KB in bytes

    if (selectedImage && selectedImage.size > maxSize) {
      setErrorMessage(
        "File size exceeds 50KB limit. Please choose a smaller file."
      );
      e.target.value = ""; // Reset file input
    } else {
      setImage(selectedImage);
      setErrorMessage("");
    }
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <header class="modal-header">
        <h5 class="modal-title">Add Home Slider Image</h5>
        <button
          type="button"
          aria-label="Close"
          class="close"
          onClick={handleCloseModal}
        >
          ×
        </button>
      </header>

      <Modal.Body>
        <div>
          <label>Type</label>
          <select className="form-select" placeholder="Enter Type">
            <option>Default</option>
            <option>Category</option>
            <option>Product</option>
            {/* <option>Slider URl</option> */}
          </select>

          <label>Status</label>
          <div
            role="radiogroup"
            tabIndex="-1"
            className="btn-group-toggle btn-group bv-no-focus-ring"
          >
            <label
              className={`btn btn-outline-primary ${
                selectedOption === "limited" ? "active" : ""
              }`}
            >
              <input
                type="radio"
                value="limited"
                checked={selectedOption === "limited"}
                onChange={handleOptionChange}
              />
              <span>Activated</span>
            </label>
            <label
              className={`btn btn-outline-primary ${
                selectedOption === "unlimited" ? "active" : ""
              }`}
            >
              <input
                type="radio"
                value="unlimited"
                checked={selectedOption === "unlimited"}
                onChange={handleOptionChange}
              />
              <span>Deactivated</span>
            </label>
          </div>
          <label>Image</label>
          <input
            type="file"
            accept="image/*"
            className="form-control"
            onChange={handleImageChange}
            disabled={!!errorMessage} // Disable file input if there's an error
          />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          {image && (
            <img src={URL.createObjectURL(image)} alt="Uploaded Image" />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success">Save</Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Homeslider;
