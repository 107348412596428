import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiDashboard3Fill } from "react-icons/ri";
import { RiShoppingCartFill } from "react-icons/ri";
import { MdCategory } from "react-icons/md";
import { GiTwoCoins } from "react-icons/gi";
import "./Sidebar.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiFillPicture } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { MdMan } from "react-icons/md";
import { FaPuzzlePiece } from "react-icons/fa";
import { IoNotifications } from "react-icons/io5";
import { IoGiftSharp } from "react-icons/io5";
import { RiArrowDropUpLine } from "react-icons/ri";
import { IoIosPeople } from "react-icons/io";
import { MdPolicy } from "react-icons/md";
import { GiCharacter } from "react-icons/gi";
import { GiFiles } from "react-icons/gi";
import { RiListSettingsFill } from "react-icons/ri";
import { MdDeliveryDining } from "react-icons/md";
import falcon from "../Assets/logo.png";
import { BiSolidUserCircle } from "react-icons/bi";
import { FaMobileScreen } from "react-icons/fa6";
import { PiKeyReturnFill } from "react-icons/pi";
import { IoGitPullRequestSharp } from "react-icons/io5";
import { MdReport } from "react-icons/md";
import { MdDashboardCustomize } from "react-icons/md";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
function Sidebar() {
  const Navigate = useNavigate();
  const [active, setActive] = useState();
  console.log({active:active})
  const [permissionview, setPermissionview] = useState("");
  const [permission, setdashPermissions] = useState("");
  const [ordersview, setOrdersview] = useState("");
  const [categories, setCategories] = useState("");
  const [headmaster, setHeadmaster] = useState("");
  const [subcategories, setSubcategories] = useState("");
  console.log({ categories: categories });
  // console.log({ordersview:ordersview.permission.OrdersViewview})
  const [products, setProducts] = useState(false);
  const [report, setReport] = useState(false);
  const [slider, setSlider] = useState(false);
  const [offer, setoffer] = useState(false);
  const [promo, setPromo] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [feature, setFeature] = useState(false);
  const [notify, setNotify] = useState(false);
  const [variant, setVariant] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentColor, setCurrentColor] = useState("");
  console.log({ currentColor: currentColor });


   useEffect(() => {
    const storedValue = localStorage.getItem("activeItem") || "";
    console.log({storedValue:storedValue})
     setActive(storedValue);
  }, []);
 
  const handleVariant = () => {
    setVariant(!variant);
  };

  const handleItemClick = (itemName) => {
    if (active === itemName) {
      setActive("");
      localStorage.removeItem("activeItem");
    } else {
      setActive(itemName);
      localStorage.setItem("activeItem", itemName);
    }
  };
  useEffect(() => {
    const savedColor = localStorage.getItem("selectedColor");
    if (savedColor) {
      setCurrentColor(JSON.parse(savedColor));
    }
  }, []);

  const appStyle = {
    height: "100vh",
    color: "white",
    backgroundColor: currentColor.hex,
    transition: "ease all 300ms",
  };

  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, []);

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const handleOptionClick = (option, path) => {
    setSelectedOption(option);
    localStorage.setItem("selectedOption", option);
    Navigate(path);
  };

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Process the fetched data
      setPermissionview(data.permission);
      setdashPermissions(data.permission.dashboardView);
      setOrdersview(data.permission.OrdersViewview);
      setCategories(data.permission.categoriesViewview);
      setHeadmaster(data.permission.headMasterViewview);
      setSubcategories(data.permission.subCategoriesViewview);
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  return (
    <div className="side_head">
      <div>
        <img src={falcon} className="falcon-logo" />
      </div>
      {permission === "1" ? (
        <div
          className={`sidebar-crct ${active === "dashboard" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("dashboard");
            Navigate("/dashboard");
          }}
        >
          <h5 className="dash">
            <MdDashboardCustomize className="dash_img" />
            Dashboard
          </h5>
        </div>
      ) : (
        ""
      )}

      {ordersview === "1" ? (
        <div
          className={`sidebar-crct ${active === "orders" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("orders");
            Navigate("/orderlist");
          }}
        >
          <h5 className="dash">
            <RiShoppingCartFill className="dash_img" />
            Orders
          </h5>
        </div>
      ) : (
        ""
      )}
      {categories === "1" ? (
        <div
          className={`sidebar-crct ${active === "categories" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("categories");
          }}
        >
          <h5
            className="dash"
            onMouseDown={(e) => {
              if (e.detail > 2) {
                e.preventDefault(); // Prevent default behavior only for double-click
              }
            }}
          >
            <MdCategory className="dash_img" />
            Category
          </h5>
          <div>
            {active === "categories" ? (
              <RiArrowDropUpLine className="dropdown" />
            ) : (
              <RiArrowDropDownLine className="dropdown" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {active === "categories" && (
        <div className="dropdown_content">
          {headmaster === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("headmaster", "/categoriemaster")
              }
              className={selectedOption === "headmaster" ? "selected" : ""}
            >
              Head Category
            </p>
          ) : (
            ""
          )}
          {subcategories === "1" ? (
            <p
              onClick={() => handleOptionClick("subcategories", "/categories")}
              className={selectedOption === "subcategories" ? "selected" : ""}
            >
              Sub Category
            </p>
          ) : (
            ""
          )}

          {/* <p
            onClick={() =>
              handleOptionClick("ordercategories", "/categoriesorder")
            }
            className={selectedOption === "ordercategories" ? "selected" : ""}
          >
            Order Categories
          </p> */}
        </div>
      )}
      {permissionview.productsViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "products" ? "active1" : ""}`}
          onClick={() => handleItemClick("products" ) }
        >
          <div>
            <h5 className="dash">
              <GiTwoCoins className="dash_img" />
              Products
            </h5>
          </div>
          <div>
            {active === "products" ? (
              <RiArrowDropUpLine className="dropdown" />
            ) : (
              <RiArrowDropDownLine className="dropdown" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {active === "products" && (
        <div className="dropdown_content">
          {/* {permissionview.addProductViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("addproduct", "/product")}
              className={selectedOption === "addproduct" ? "selected" : ""}
            >
              Add Product
            </p>
          ) : (
            ""
          )} */}
          {permissionview.manageProductsViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("manageproducts", "/manageproduct")
              }
              className={selectedOption === "manageproducts" ? "selected" : ""}
            >
              Manage Products
            </p>
          ) : (
            ""
          )}
           {permissionview.unitsViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("variant", "/variant")}
              className={selectedOption === "variant" ? "selected" : ""}
            >
             Product Variants
            </p>
          ) : (
            ""
          )}
          {permissionview.unitsViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("units", "/units")}
              className={selectedOption === "units" ? "selected" : ""}
            >
              Units
            </p>
          ) : (
            ""
          )}

          {/* <p
            onClick={() => handleOptionClick("bulkupload", "/bulkupload")}
            className={selectedOption === "bulkupload" ? "selected" : ""}
          >
            Bulk Upload
          </p> */}
          {permissionview.taxesViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("taxes", "/taxes")}
              className={selectedOption === "taxes" ? "selected" : ""}
            >
              Taxes
            </p>
          ) : (
            ""
          )}
          {permissionview.BrandsViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("brands", "/brands")}
              className={selectedOption === "brands" ? "selected" : ""}
            >
              Brands
            </p>
          ) : (
            ""
          )}

          {/* <p
            onClick={() => {
              setSelectedOption("productorder");
              localStorage.setItem("selectedOption", "productorder");
              // Handle navigation or other actions for "Product Order"
            }}
            className={selectedOption === "productorder" ? "selected" : ""}
          >
            Product Order
          </p> */}
        </div>
      )}
      {permissionview.MasterViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "variant" ? "active1" : ""}`}
          onClick={() => {
            handleVariant();
            handleItemClick("variant");
          }}
        >
          <div>
            <h5 className="dash">
              <IoIosPeople className="dash_img" />
              Master
            </h5>
          </div>
          <div>
            <div>
              {active === "variant" ? (
                <RiArrowDropUpLine className="dropdown" />
              ) : (
                <RiArrowDropDownLine
                  className="dropdown"
                  onClick={handleItemClick}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {active === "variant" && (
        <div className="dropdown_content">
          {permissionview.VariantMasterViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("variantmaster", "/variantmaster")
              }
              className={selectedOption === "variantmaster" ? "selected" : ""}
            >
              Variant Master
            </p>
          ) : (
            ""
          )}
          {permissionview.SizeMasterViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("sizemaster", "/sizemaster")}
              className={selectedOption === "sizemaster" ? "selected" : ""}
            >
              Size Master
            </p>
          ) : (
            ""
          )}
          {permissionview.ColourMasterViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("colourmaster", "/colourmaster")}
              className={selectedOption === "colourmaster" ? "selected" : ""}
            >
              Colour Master
            </p>
          ) : (
            ""
          )}
          {permissionview.cellularmemorystoragecapacityViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick(
                  "cellularmemorystoragecapacity",
                  "/cellularmemorystoragecapacity"
                )
              }
              className={
                selectedOption === "cellularmemorystoragecapacity"
                  ? "selected"
                  : ""
              }
            >
             Expandable Storage
            </p>
          ) : (
            ""
          )}
          {permissionview.processorspeedViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("processorspeed", "/processorspeed")
              }
              className={selectedOption === "processorspeed" ? "selected" : ""}
            >
              Processor Speed
            </p>
          ) : (
            ""
          )}

          {permissionview.BatteryViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("batterycapacity", "/batterycapacity")
              }
              className={selectedOption === "batterycapacity" ? "selected" : ""}
            >
              Battery Capacity
            </p>
          ) : (
            ""
          )}
          {permissionview.GPSViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("gps", "/gps")}
              className={selectedOption === "gps" ? "selected" : ""}
            >
              GPS
            </p>
          ) : (
            ""
          )}
         
          {permissionview.PhoneViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("phoneresolution", "/phoneresolution")
              }
              className={selectedOption === "phoneresolution" ? "selected" : ""}
            >
              Display Resolution
            </p>
          ) : (
            ""
          )}
          {permissionview.RefreshViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("refreshrate", "/refreshrate")}
              className={selectedOption === "refreshrate" ? "selected" : ""}
            >
              Refresh Rate
            </p>
          ) : (
            ""
          )}
          {permissionview.RamViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("ramsize", "/ramsize")}
              className={selectedOption === "ramsize" ? "selected" : ""}
            >
              RAM Size
            </p>
          ) : (
            ""
          )}
          {permissionview.SensorViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("sensorresolution", "/sensorresolution")
              }
              className={
                selectedOption === "sensorresolution" ? "selected" : ""
              }
            >
             Quick Charging
            </p>
          ) : (
            ""
          )}
          {permissionview.ShootingViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("shootingmodes", "/shootingmodes")
              }
              className={selectedOption === "shootingmodes" ? "selected" : ""}
            >
              Audio Jack
            </p>
          ) : (
            ""
          )}
          {permissionview.CellularViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("cellulartech", "/cellulartech")}
              className={selectedOption === "cellulartech" ? "selected" : ""}
            >
              Cellular Technology
            </p>
          ) : (
            ""
          )}
          {permissionview.SimViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("sim", "/sim")}
              className={selectedOption === "sim" ? "selected" : ""}
            >
              Sim Card Size
            </p>
          ) : (
            ""
          )}
          {permissionview.OperatingViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("os", "/os")}
              className={selectedOption === "os" ? "selected" : ""}
            >
              Operating System
            </p>
          ) : (
            ""
          )}
          {permissionview.MobilephoneViewViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("humanint", "/humanint")}
              className={selectedOption === "humanint" ? "selected" : ""}
            >
              Device Interface
            </p>
          ) : (
            ""
          )}
          {permissionview.CellularPhoneview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("cellularphone", "/cellularphone")
              }
              className={selectedOption === "cellularphone" ? "selected" : ""}
            >
              Rear Camera
            </p>
          ) : (
            ""
          )}
           {permissionview.CameraViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("cameraresolution", "/cameraresolution")
              }
              className={
                selectedOption === "cameraresolution" ? "selected" : ""
              }
            >
              Front Camera 
            </p>
          ) : (
            ""
          )}
          {permissionview.Videoview === "1" ? (
            <p
              onClick={() => handleOptionClick("videocapture", "/videocapture")}
              className={selectedOption === "videocapture" ? "selected" : ""}
            >
              Video Capture Quality
            </p>
          ) : (
            ""
          )}
          {permissionview.Itemview === "1" ? (
            <p
              onClick={() => handleOptionClick("item", "/item")}
              className={selectedOption === "item" ? "selected" : ""}
            >
              Item Weight
            </p>
          ) : (
            ""
          )}

          {permissionview.Includedview === "1" ? (
            <p
              onClick={() => handleOptionClick("include", "/include")}
              className={selectedOption === "include" ? "selected" : ""}
            >
              In The Box
            </p>
          ) : (
            ""
          )}
          {permissionview.Biometricview === "1" ? (
            <p
              onClick={() => handleOptionClick("biometric", "/biometric")}
              className={selectedOption === "biometric" ? "selected" : ""}
            >
             Mobile Security Lock
            </p>
          ) : (
            ""
          )}
          {permissionview.Connectorview === "1" ? (
            <p
              onClick={() => handleOptionClick("connector", "/connector")}
              className={selectedOption === "connector" ? "selected" : ""}
            >
              Phone Connector Type
            </p>
          ) : (
            ""
          )}
          {permissionview.Connectivityview === "1" ? (
            <p
              onClick={() => handleOptionClick("connectivity", "/connectivity")}
              className={selectedOption === "connectivity" ? "selected" : ""}
            >
             Connectivity Technologies
            </p>
          ) : (
            ""
          )}
          {permissionview.Screenview === "1" ? (
            <p
              onClick={() => handleOptionClick("size", "/size")}
              className={selectedOption === "size" ? "selected" : ""}
            >
              Screen Size
            </p>
          ) : (
            ""
          )}
          {permissionview.Displayview === "1" ? (
            <p
              onClick={() => handleOptionClick("displaytype", "/displaytype")}
              className={selectedOption === "displaytype" ? "selected" : ""}
            >
              Display Type
            </p>
          ) : (
            ""
          )}
          {permissionview.Deviceview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("devicefeatures", "/devicefeatures")
              }
              className={selectedOption === "devicefeatures" ? "selected" : ""}
            >
              Device Features
            </p>
          ) : (
            ""
          )}
          {permissionview.Slotview === "1" ? (
            <p
              onClick={() => handleOptionClick("slotcount", "/slotcount")}
              className={selectedOption === "slotcount" ? "selected" : ""}
            >
              Sim Card Slot Count
            </p>
          ) : (
            ""
          )}
          {permissionview.Shortview === "1" ? (
            <p
              onClick={() => handleOptionClick("shortby", "/shortby")}
              className={selectedOption === "shortby" ? "selected" : ""}
            >
              Short By
            </p>
          ) : (
            ""
          )}
          {permissionview.Chargeview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("deliverycharge", "/deliverycharge")
              }
              className={selectedOption === "deliverycharge" ? "selected" : ""}
            >
              Delivery Charge
            </p>
          ) : (
            ""
          )}
          {permissionview.Shopview === "1" ? (
            <p
              onClick={() => handleOptionClick("shop", "/shop")}
              className={selectedOption === "shop" ? "selected" : ""}
            >
              Shop Master
            </p>
          ) : (
            ""
          )}
        </div>
      )}

      {permissionview.PurchaseViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "Purchase" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("Purchase");
            Navigate("/Purchase");
          }}
        >
          <div>
            <h5 className="dash">
              <MdMan className="dash_img" />
              Purchase
            </h5>
          </div>
        </div>
      ) : (
        ""
      )}
      {permissionview.OfferViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "Offer" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("Offer");
            Navigate("/Offer");
          }}
        >
          <div>
            <h5 className="dash">
              <GiCharacter className="dash_img" />
              Offer Page
            </h5>
          </div>
        </div>
      ) : (
        ""
      )}
      {permissionview.HomeViewview === "1" ? (
        <div
          className={`sidebar-crct ${
            active === "managehomeslider" ? "active1" : ""
          }`}
          onClick={() => {
            handleItemClick("managehomeslider");
            Navigate("/managehomeslider");
          }}
        >
          <div>
            <h5 className="dash">
              <GiFiles className="dash_img" />
              Home Sliders
            </h5>
          </div>
        </div>
      ) : (
        ""
      )}

      {permissionview.OfferimgViewview === "1" ? (
        <div
          className={`sidebar-crct ${
            active === "manageofferimage" ? "active1" : ""
          }`}
          onClick={() => {
            handleItemClick("manageofferimage");
            Navigate("/manageofferimage");
          }}
        >
          <div>
            <h5 className="dash">
              <AiFillPicture className="dash_img" />
              Offer Images
            </h5>
          </div>
        </div>
      ) : (
        ""
      )}

      {permissionview.PromoViewview === "1" ? (
        <div
          className={`sidebar-crct ${
            active === "managepromo" ? "active1" : ""
          }`}
          onClick={() => {
            handleItemClick("managepromo");
            Navigate("/managepromo");
          }}
        >
          <div>
            <h5 className="dash">
              <IoGiftSharp className="dash_img" />
              Promo Code
            </h5>
          </div>
        </div>
      ) : (
        ""
      )}

      {permissionview.DeliveryViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "delivery" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("delivery");
          }}
        >
          <h5
            className="dash"
            onMouseDown={(e) => {
              if (e.detail > 2) {
                e.preventDefault(); // Prevent default behavior only for double-click
              }
            }}
          >
            <MdDeliveryDining className="dash_img" />
            Delivery Boy
          </h5>
          <div>
            {active === "delivery" ? (
              <RiArrowDropUpLine className="dropdown" />
            ) : (
              <RiArrowDropDownLine className="dropdown" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {active === "delivery" && (
        <div className="dropdown_content">
          {permissionview.AddDeliveryViewview === "1" ? (
            <p
              onClick={() => handleOptionClick("adddelivery", "/adddelivery")}
              className={selectedOption === "adddelivery" ? "selected" : ""}
            >
              Add Delivery Boy
            </p>
          ) : (
            ""
          )}
          {permissionview.ManageDeliveryViewview === "1" ? (
            <p
              onClick={() =>
                handleOptionClick("managedelivery", "/managedelivery")
              }
              className={selectedOption === "managedelivery" ? "selected" : ""}
            >
              Manage Delivery Boys
            </p>
          ) : (
            ""
          )}
        </div>
      )}
      {permissionview.FeaturedViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "feature" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("feature");
          }}
        >
          <h5
            className="dash"
            onMouseDown={(e) => {
              if (e.detail > 2) {
                e.preventDefault(); // Prevent default behavior only for double-click
              }
            }}
          >
            <MdPolicy className="dash_img" />
            Featured Sections
          </h5>
          <div>
            {active === "feature" ? (
              <RiArrowDropUpLine className="dropdown" />
            ) : (
              <RiArrowDropDownLine className="dropdown" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {active === "feature" && (
        <div className="dropdown_content">
          {/* <p
            onClick={() => {
              setSelectedOption("addsection");
              Navigate("/addsection");
            }}
            className={selectedOption === "addsection" ? "selected" : ""}
          >
            Add Section
          </p> */}
          {permissionview.ManageFeaturedViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("managesection");
                Navigate("/managesection");
              }}
              className={selectedOption === "managesection" ? "selected" : ""}
            >
              Manage Section
            </p>
          ) : (
            ""
          )}
        </div>
      )}

      {/* <div
        className={`sidebar-crct ${active === "web" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("web");
          Navigate("/web");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Web Settings
        </h5>
      </div> */}

      {/* <div
        className={`sidebar-crct ${active === "policies" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("policies");
          Navigate("/policies");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Policies
        </h5>
      </div> */}

      {permissionview.ReportsViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "report" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("report");
          }}
        >
          <h5
            className="dash"
            onMouseDown={(e) => {
              if (e.detail > 2) {
                e.preventDefault(); // Prevent default behavior only for double-click
              }
            }}
          >
            <MdReport className="dash_img" />
            Reports
          </h5>
          <div>
            {active === "report" ? (
              <RiArrowDropUpLine className="dropdown" />
            ) : (
              <RiArrowDropDownLine className="dropdown" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {active === "report" && (
        <div className="dropdown_content">
          {permissionview.ProductSalesViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("productsalesreport");
                Navigate("/productsalesreport");
              }}
              className={
                selectedOption === "productsalesreport" ? "selected" : ""
              }
            >
              Product Sales Report
            </p>
          ) : (
            ""
          )}
          {permissionview.SalesViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("salesreport");
                Navigate("/salesreport");
              }}
              className={selectedOption === "salesreport" ? "selected" : ""}
            >
              Sales Report
            </p>
          ) : (
            ""
          )}
          {permissionview.OrderReportViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("orderreport");
                Navigate("/orderreport");
              }}
              className={selectedOption === "orderreport" ? "selected" : ""}
            >
              Order Report
            </p>
          ) : (
            ""
          )}
        </div>
      )}
      {permissionview.StockViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "stock" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("stock");
            Navigate("/stock");
          }}
        >
          <h5 className="dash">
            <FaPuzzlePiece className="dash_img" />
            Stock
          </h5>
        </div>
      ) : (
        ""
      )}
      {permissionview.NotificationsViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "notify" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("notify");
          }}
        >
          <h5
            className="dash"
            onMouseDown={(e) => {
              if (e.detail > 2) {
                e.preventDefault(); // Prevent default behavior only for double-click
              }
            }}
          >
            <IoNotifications className="dash_img" />
            Notifications
          </h5>
          <div>
            {active === "notify" ? (
              <RiArrowDropUpLine className="dropdown" />
            ) : (
              <RiArrowDropDownLine className="dropdown" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {active === "notify" && (
        <div className="dropdown_content">
          {/* <p
            onClick={() => {
              setSelectedOption("sendnotification");
              Navigate("/sendnotification");
            }}
            className={selectedOption === "sendnotification" ? "selected" : ""}
          >
            Send Notification
          </p> */}
          {permissionview.ManageNotificationsViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("managenotification");
                Navigate("/managenotification");
              }}
              className={
                selectedOption === "managenotification" ? "selected" : ""
              }
            >
              Manage Notification
            </p>
          ) : (
            ""
          )}
        </div>
      )}
      {permissionview.SystemViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "system" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("system");
          }}
        >
          <h5
            className="dash"
            onMouseDown={(e) => {
              if (e.detail > 2) {
                e.preventDefault(); // Prevent default behavior only for double-click
              }
            }}
          >
            <IoNotifications className="dash_img" />
            System Users
          </h5>
          <div>
            {active === "system" ? (
              <RiArrowDropUpLine className="dropdown" />
            ) : (
              <RiArrowDropDownLine className="dropdown" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {active === "system" && (
        <div className="dropdown_content">
          {permissionview.AddSystemViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("systemusers");
                Navigate("/systemusers");
              }}
              className={selectedOption === "systemusers" ? "selected" : ""}
            >
              Add System Users
            </p>
          ) : (
            ""
          )}
          <p
            onClick={() => {
              setSelectedOption("role");
              Navigate("/role");
            }}
            className={selectedOption === "role" ? "selected" : ""}
          >
            Add Role
          </p>
          {permissionview.RoleView === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("role");
                Navigate("/role");
              }}
              className={selectedOption === "role" ? "selected" : ""}
            >
              Add Role
            </p>
          ) : (
            ""
          )}
        </div>
      )}

      {permissionview.MobileViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "mobile" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("mobile");
            Navigate("/mobileusers ");
          }}
        >
          <h5 className="dash">
            <FaMobileScreen className="dash_img" />
            Mobile Users
          </h5>
        </div>
      ) : (
        ""
      )}
      {permissionview.ReturnRequestsViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "return" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("return");
            Navigate("/ReturnRequest ");
          }}
        >
          <h5 className="dash">
            <PiKeyReturnFill className="dash_img" />
            Return Requests
          </h5>
        </div>
      ) : (
        ""
      )}
      {permissionview.WithdrawalRequestsViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "withdraw" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("withdraw");
            Navigate("/Withdrawalrequest ");
          }}
        >
          <h5 className="dash">
            <IoGitPullRequestSharp className="dash_img" />
            Withdrawal Request
          </h5>
        </div>
      ) : (
        " "
      )}

      {/* <div
        className={`sidebar-crct ${active === "role" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("role");
          Navigate("/role ");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Role
        </h5>
      </div> */}
      {/* <div
        className={`sidebar-crct ${active === "Setting" ? "active1" : ""}`}
        onClick={() => {
          handleItemClick("Setting");
          Navigate("/Setting ");
        }}
      >
        <h5 className="dash">
          <RiDashboard3Fill className="dash_img" />
          Settings
        </h5>
      </div> */}
      {permissionview.FAQViewview === "1" ? (
        <div
          className={`sidebar-crct ${active === "faq" ? "active1" : ""}`}
          onClick={() => {
            handleItemClick("faq");
            Navigate("/faq ");
          }}
        >
          <h5 className="dash">
            <RiDashboard3Fill className="dash_img" />
            FAQ's
          </h5>
        </div>
      ) : (
        ""
      )}

      {permissionview.PolicyViewview === "1" ? (
        <div
          className={`sidebar-crct ${
            active === "privacypolicy" ? "active1" : ""
          }`}
          onClick={() => {
            handleItemClick("privacypolicy");
          }}
        >
          <h5
            className="dash"
            onMouseDown={(e) => {
              if (e.detail > 2) {
                e.preventDefault(); // Prevent default behavior only for double-click
              }
            }}
          >
            <MdCategory className="dash_img" />
            Policy
          </h5>
          <div>
            {active === "privacypolicy" ? (
              <RiArrowDropUpLine className="dropdown" />
            ) : (
              <RiArrowDropDownLine className="dropdown" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {active === "privacypolicy" && (
        <div className="dropdown_content">
          {permissionview.PrivacyPolicyViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("privacypolicy");
                Navigate("/privacypolicy");
              }}
              className={selectedOption === "privacypolicy" ? "selected" : ""}
            >
              Privacy Policy
            </p>
          ) : (
            ""
          )}
          {permissionview.AboutPolicyViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("Aboutus");
                Navigate("/Aboutus");
              }}
              className={selectedOption === "Aboutus" ? "selected" : ""}
            >
              About Us
            </p>
          ) : (
            ""
          )}
          {permissionview.DeliveryPolicyViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("deliverypolicy");
                Navigate("/deliverypolicy");
              }}
              className={selectedOption === "deliverypolicy" ? "selected" : ""}
            >
              Delivery Policy
            </p>
          ) : (
            ""
          )}
          {permissionview.TermsPolicyViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("termsandcondition");
                Navigate("/termsandcondition");
              }}
              className={
                selectedOption === "termsandcondition" ? "selected" : ""
              }
            >
              Terms and Condition
            </p>
          ) : (
            ""
          )}
          {permissionview.RefundPolicyViewview === "1" ? (
            <p
              onClick={() => {
                setSelectedOption("refundpolicy");
                Navigate("/refundpolicy");
              }}
              className={selectedOption === "refundpolicy" ? "selected" : ""}
            >
              Refund Policy
            </p>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
