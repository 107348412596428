import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import css from "./Categories.css";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import swal from "sweetalert";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";

function Categories() {
  const [showModal, setShowModal] = useState(false);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [parentcategory, setParentcategory] = useState("");
  const [subMasterView, setSubMasterView] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);

  const [categoriesload, setCategoriesload] = useState([]);
  const [selectedOption, setSelectedOption] = useState("limited"); // Define selectedOption state
  const [totalItems, setTotalItems] = useState(0);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const [editCategory, setEditCategory] = useState({
    id: "",
    parent_category: "",
    category_name: "",
    // category_subtitle: "",
    image: "",
    status: "",
  });
  const [filename, setFilename] = useState("");
  const Navigate = useNavigate();

  const [CategoryName, setCategoryName] = useState("");
  const [CategorySubtitle, setCategorySubtitle] = useState("");
  const [status, setStatus] = useState("1");
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setStatus(event.target.value);
  };
  useEffect(() => {
    setLoading(true);
    GetData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setSelectedOption("limited");
      setLoading(false); // Make sure to set loading to false here
    }, 1000);

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  const GetData = async () => {
    const data = await fetch("https://arasurdigital.com/admin/categories.php");
    const response = await data.json();
    setCategories(response);
  };
  useEffect(() => {
    Dataget();
    setFilename(editCategory.image);
  }, []);
  const Dataget = async () => {
    const data = await fetch(
      "https://arasurdigital.com/admin/categoriesmaster.php"
    );
    const response = await data.json();
    setCategoriesload(response);
  };

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setImage(selectedFile);
  // };
  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 140 * 1024; // 140KB in bytes

    if (selectedImage && selectedImage.size < maxSize) {
      setImage(selectedImage);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 140KB");
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;

    const newValue = value.replace(/[^a-zA-Z ]/g, "");
    setCategoryName(newValue);
  };

  const handleInsert = () => {
    // Check if any required values are missing
    if (!parentcategory || !CategoryName || !status || !image) {
      Swal.fire({
        icon: "error",
        title: "Missing Information",
        text: "All values are required for insertion.",
      });
      return;
    }

    // Check if the image file size is greater than 140KB
    if (image instanceof File && image.size > 140 * 1024) {
      // 140KB in bytes
      Swal.fire({
        icon: "error",
        title: "File Too Large",
        text: "The image file size should not exceed 140KB.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("parent_category", parentcategory);
    formData.append("category_name", CategoryName);
    formData.append("category_subtitle", CategorySubtitle);
    formData.append("status", status);
    formData.append("image", image);

    fetch("https://arasurdigital.com/admin/categories.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        GetData();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Category inserted successfully.",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong while inserting the category.",
        });
      });

    // Reset form fields and hide the modal
    setSelectedOption("");
    setCategoryName("");
    setImage("");
    setParentcategory("");
    setCategorySubtitle("");
    setShowModal(false);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [query]);

  const handelDelete = async (categoryId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/categories.php?id=${categoryId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setCategories(
            categories.filter((category) => category.id !== categoryId)
          );
          swal("Deleted!", "Category has been deleted.", "success");
        } else {
          console.error("Failed to delete category");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelEdit = async (id) => {
    // console.log("id", id);
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/categories.php?id=${id}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === id);
        if (categoryDetails) {
          setEditCategory(categoryDetails);
          setEditShowModal(true);
        } else {
          console.error("Category with id", id, "not found");
        }
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      if (
        editCategory.parent_category === "" ||
        !editCategory.category_name ||
        !editCategory.status
      ) {
        Swal.fire({
          icon: "error",
          title: "Missing Information",
          text: "All values are required for insertion.",
        });
        return;
      }
  
      const formData = new FormData();
      formData.append("id", editCategory.id);
      formData.append("parent_category", editCategory.parent_category);
      formData.append("category_name", editCategory.category_name);
      formData.append("image", imageFile);
      formData.append("current_image", editCategory.image);
      formData.append("status", editCategory.status);
  
      const response = await fetch(
        `https://arasurdigital.com/admin/categorioesupdate.php?id=${editCategory.id}`,
        {
          method: "POST",
          body: formData,
        }
      );
  
      if (response.status === 200) {
        setCategories(
          categories.map((category) =>
            category.id === editCategory.id ? editCategory : category
          )
        );
  
        // Success message
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Category updated successfully!",
        });
  
        // Reset form and close modal
        setEditShowModal(false);
        setParentcategory("");
        setCategoryName("");
        setCategorySubtitle("");
        setImage("");
        setImageFile("");
        setFilename("");
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    GetData();
  };
  

  const handleCloseModal = () => {
    setParentcategory("");
    setCategoryName("");
    setCategorySubtitle("");
    setStatus("1");
    setImage(null);
    setShowModal(false);
    setErrorMessage("");
    setShowModal(false);
  };

  useEffect(() => {
    GetData();
  }, []);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const minSize = 140 * 1024; // 140KB in bytes
    if (file && file.size < minSize) {
      setImageFile(file);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 140KB");
    }
  };

  useEffect(() => {
    const result = categories.filter((category) => {
      const parentCategoryMatch = category.parent_category
        .toLowerCase()
        .includes(query.toLowerCase());
      const categoryNameMatch = category.category_name
        .toLowerCase()
        .includes(query.toLowerCase());
      const categorySubtitleMatch = category.category_subtitle
        .toLowerCase()
        .includes(query.toLowerCase());
      const imageMatch = category.image
        .toLowerCase()
        .includes(query.toLowerCase());
      const statusMatch = category.status
        .toLowerCase()
        .includes(query.toLowerCase());
      return (
        parentCategoryMatch ||
        categoryNameMatch ||
        categorySubtitleMatch ||
        imageMatch ||
        statusMatch
      );
    });
    setFilter(result);
  }, [query, categories]);

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };
  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");

      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setSubMasterView(data.permission.subCategoriesView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const getParentCategoryName = (id) => {
    const category = categoriesload.find((cat) => cat.id === id);
    return category ? category.category_name : "N/A";
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [query]);
  const handleChange = (event) => {
    const { value } = event.target;
    setEditCategory((prev) => ({
      ...prev,
      status: Number(value), // Ensure value is a number
    }));
  };
  const [variant, setVariant] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(categories);
  }, [categories]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.category_name || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, query]);
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Sub Category</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Sub Category
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card-cat">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                {/* <label>Entries</label> */}
              </div>
            </div>
            {/* <div className="search-left">
                <div className="searchbar">
                  <input
                    type="text"
                    className="search"
                    placeholder="Search"
                    onChange={handleInput}
                  />
                  <FaSearch className="search-icon" />
                </div>
              </div>
              {subMasterView.add === true ? (
                <button className="add-category-button" onClick={handleShowModal}>
                  Add Category
                </button>
              ) : (
                ""
              )} */}

            <div className="d-flex align-items-center ms-auto">
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
              <div style={{ padding: "10px", marginBottom: "6px" }}>
                {subMasterView.add === true ? (
                  <button
                    className="add-category-button"
                    onClick={handleShowModal}
                  >
                    Add Sub Category
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="table-container text-end">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>Sub Category </th> */}
                    <th
                      onClick={() => handleSortByColumn("category_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Sub Category
                      {sortColumn === "category_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th style={{ textAlign: "center" }}>Image</th>
                    {/* <th style={{ textAlign: "center" }}>Head Category </th> */}
                    <th
                      onClick={() => handleSortByColumn("parent_category")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Head Category
                      {sortColumn === "parent_category" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Subtitle</th> */}
                    <th style={{ textAlign: "center" }}>Status</th>
                    {subMasterView.edit === true ||
                    subMasterView.delete === true ? (
                      <th style={{ textAlign: "center" }}>Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((category, index) => (
                      <tr key={category.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {category.category_name}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          <img
                            src={`https://arasurdigital.com/admin/image/categories/${category.image}`}
                            width={40}
                            height={40}
                            style={{ margin: "10px" }}
                            alt={category.category_name}
                          />
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {getParentCategoryName(category.parent_category)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {category.status === "1" ? (
                            <span className="active-item">Active</span>
                          ) : (
                            <span className="inactive-item">In-Active</span>
                          )}
                        </td>

                        {(subMasterView.edit || subMasterView.delete) && (
                          <td style={{ textAlign: "center" }}>
                            {subMasterView.edit && (
                              <button
                                className="btn-edit"
                                onClick={() => handelEdit(category.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {subMasterView.delete && (
                              <button
                                className="btn-delete"
                                onClick={() => handelDelete(category.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Sub Category</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Head Category <i className="text-danger ">*</i>
              </label>
              <select
                className="form-select"
                placeholder="Select Head Category"
                value={parentcategory}
                onChange={(e) => setParentcategory(e.target.value)}
              >
                <option value="">Select Head Category</option>
                {categoriesload.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>

              <label className="bold">
                Sub Category Name <i className="text-danger ">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Category Name"
                value={CategoryName}
                onChange={(e) => {
                  setCategoryName(e.target.value);
                  handleInputChange(e);
                }}
              />

              <label className="bold">
                Status <i className="text-danger ">*</i>
              </label>
              <div className="form-switch formswitch d-flex align-items-center">
                <input
                  className={`form-check-input custom-switch w-100 ${
                    status === "1" ? "bg-success" : "bg-danger"
                  }`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={status === "1"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "1" : "0";
                    setStatus(newValue);
                  }}
                />
                <label
                  htmlFor="flexSwitchCheckDefault"
                  className="status-label ms-2 mb-0"
                >
                  {status === "1" ? (
                    <span className="text-success w-20">Active</span>
                  ) : (
                    <span className="text-danger ">In-Active</span>
                  )}
                </label>
              </div>

              <p className="bold">
                Image <i className="text-danger ">*</i>
              </p>
              <p>Please choose a square image less 140KB.</p>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Update Category</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label className="bold">
                Head Category <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                value={editCategory.parent_category}
                onChange={(e) =>
                  setEditCategory({
                    ...editCategory,
                    parent_category: e.target.value,
                  })
                }
              >
                <option value="">Select Head Category </option>
                {categoriesload.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
              <label className="bold">
                Sub Category Name <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={editCategory.category_name}
                onChange={(e) => {
                  const { value } = e.target;
                  // Allow only letters and spaces
                  const letterAndSpaceOnlyValue = value.replace(
                    /[^a-zA-Z\s]/g,
                    ""
                  );
                  setEditCategory({
                    ...editCategory,
                    category_name: letterAndSpaceOnlyValue,
                  });
                }}
                placeholder="Enter Sub Category Name"
                pattern="[a-zA-Z\s]*"
              />

              <div className="">
                <label className="bold">
                  Status <i className="text-danger">*</i>
                </label>
                <br />

                <div className="form-switch formswitch d-flex align-items-center">
                  <input
                    className={`form-check-input custom-switch w-100 ${
                      editCategory.status === "1" ? "bg-success" : "bg-danger"
                    }`}
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={editCategory.status === "1"}
                    onClick={() => {
                      setEditCategory({
                        ...editCategory,
                        status: editCategory.status === "1" ? "0" : "1",
                      });
                    }}
                  />
                  <label
                    htmlFor="flexSwitchCheckDefault"
                    className="status-label ms-2 mb-0"
                  >
                    {editCategory.status === "1" ? (
                      <span className="text-success w-20">Active</span>
                    ) : (
                      <span className="text-danger ">In-Active</span>
                    )}
                  </label>
                </div>

                <div className="image-upload-section">
                  <label className="bold">
                    Image <i className="text-danger">*</i>
                  </label>
                  <p>Please choose a square image less than 140KB.</p>
                  <input
                    className="form-control p-2"
                    style={{ width: "80%" }}
                    type="file"
                    name="file2"
                    id="myFile2"
                    accept=".jpg, .jpeg, .png"
                    onChange={(e) => {
                      handleFileInputChange(e);
                    }}
                  />
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  <input
                    className="form-control"
                    value={editCategory.image}
                    name="current_image"
                  />
                  <span id="file-name" hidden>
                    {filename}
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Categories;
