import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Lottie from "react-lottie";
import swal from "sweetalert";
import Swal from "sweetalert2";
import "./CategoriesMaster.css";
import { useNavigate } from "react-router-dom";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
function CategoriesMaster() {
  const [showModal, setShowModal] = useState(false);
  const [categoryname, setCategoryname] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState("");
  const [EditshowModal, setEditShowModal] = useState(false);
  const [filter, setFilter] = useState([]);
  const [option, setOption] = useState([]);
  console.log({ option: option });
  const [headMasterView, setHeadMasterView] = useState("");
  console.log({ headMasterView: headMasterView });
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [editCategory, setEditCategory] = useState({
    id: "",
    category_name: "",
    variants: [],
    category_image: "",
    filter: "",
  });

  const [image, setImage] = useState(null);
  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [variantOptions, setVariantOptions] = useState([]);

  const [variant, setVariant] = useState("");
  const [selectedVariants, setSelectedVariants] = useState([]);
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleCloseModal = () => {
    setSelectedVariants("");
    setCategoryImage("");
    setCategoryname("");
    setOption("");
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [query]);

  useEffect(() => {
    setFilename(editCategory.category_image);
  }, [editCategory]);

  useEffect(() => {
    setLoading(true);
    GetData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [query]);

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    // Calculate the current page after changing items per page
    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const GetData = async () => {
    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/categoriesmaster.php"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch categories");
      }
      const data = await response.json();
      setCategories(data);
      setFilter(data); // Assuming filter should initially be all categories
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const result = categories.filter((category) => {
      if (!category.category_name) return false;
      const categoryNameMatch = category.category_name
        .toLowerCase()
        .includes(query.toLowerCase());
      return categoryNameMatch;
    });
    setFilter(result);
  }, [query, categories]);

  const handleInputChange = (e) => {
    const { value } = e.target;

    // Check if the input value contains only alphabets and spaces
    const newValue = value.replace(/[^a-zA-Z ]/g, "");
    setCategoryname(newValue);
    setEditCategory({
      ...editCategory,
      category_name: newValue,
    });
  };
  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 300 * 1024; // 300KB in bytes

    if (selectedImage && selectedImage.size <= maxSize) {
      setCategoryImage(selectedImage);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 300KB");
    }
  };

  const handleFilterChange = (e) => {
    setOption(e.target.value);
  };

  const handleInsert = () => {
    // Check if an image is selected and its size exceeds 300KB (300 * 1024 bytes)
    if (categoryImage && categoryImage.size > 300 * 1024) {
      Swal.fire({
        icon: "error",
        title: "Image Too Large",
        text: "The image size exceeds 300KB. Please choose a smaller image.",
      });
      return;
    }

    // Check for missing fields
    if (
      !categoryname ||
      selectedVariants.length === 0 ||
      !option ||
      !categoryImage
    ) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Category name,  variants,  filter,image  are required and The image size exceeds 300KB.",
      });
      return;
    }

    // Proceed with form submission if validation passes
    const formData = new FormData();
    formData.append("category_name", categoryname);
    formData.append("category_image", categoryImage);
    formData.append("variants", selectedVariants);
    formData.append("filter", option);

    fetch("https://arasurdigital.com/admin/categoriesmaster.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // Reset form fields
    setSelectedVariants("");
    setCategoryImage("");
    setCategoryname("");
    setOption("");
    setShowModal(false);
  };

  const handleCheckboxChangeEdit = (variantId) => {
    setEditCategory((prevCategory) => {
      // Ensure prevCategory.variants is always a string
      const currentVariantsString =
        typeof prevCategory.variants === "string"
          ? prevCategory.variants
          : Array.isArray(prevCategory.variants)
          ? prevCategory.variants.join(",") // Convert array to string
          : ""; // Handle initial state if variants is undefined

      // Convert string to array
      const currentVariants = currentVariantsString.split(",").filter(Boolean); // Filter out empty strings

      // Check if the variantId is already selected
      const isVariantSelected = currentVariants.includes(variantId.toString()); // Ensure variantId is compared as string

      let updatedVariants;

      if (isVariantSelected) {
        // Variant is already selected, remove it
        updatedVariants = currentVariants.filter(
          (id) => id !== variantId.toString()
        );
      } else {
        // Variant is not selected, add it
        updatedVariants = [...currentVariants, variantId.toString()];
      }

      // Return the updated state with variants as string
      return { ...prevCategory, variants: updatedVariants.join(",") };
    });
  };

  const handleUpdate = async () => {
    try {
      if (
        !editCategory.category_name ||
        editCategory.variants.length === 0 ||
        (!imageFile && !editCategory.category_image)
      ) {
        Swal.fire({
          icon: "warning",
          title: "Validation Error",
          text: "Category name, variants, filter, and image are required.",
        });
        return;
      }
      const formData = new FormData();
      formData.append("id", editCategory.id);
      formData.append("category_name", editCategory.category_name);
      // const variantsString = editCategory.variants.join(",");
      formData.append("variants", editCategory.variants);
      formData.append("filter", editCategory.filter);
      if (imageFile) {
        formData.append("category_image", imageFile);
      } else {
        formData.append("category_image", editCategory.category_image);
      }
      for (let [key, value] of formData.entries()) {
      }
      const response = await fetch(
        `https://arasurdigital.com/admin/categoriesmasterupdate.php?id=${editCategory.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        // const data = await response.json();
        console.log("Category updated successfully:");
        setCategories(
          categories.map((category) =>
            category.id === editCategory.id ? editCategory : categories
          )
        );
        setEditShowModal(false);
        setCategoryname("");
        setCategoryImage(null);
        setEditCategory({
          category_name: "",
          variants: [],
          category_image: "",
        });
        setImageFile(null);
        setFilename("");
        GetData();
      } else {
        const errorData = await response.text();
        console.error("Failed to update category:", errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/categoriesmaster.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Variant deleted successfully!");
          GetData(); // Refresh the variants after deleting
        } else {
          console.error("Failed to delete variant");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  const handelEdit = async (id) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/categoriesmaster.php?id=${id}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === id);
        if (categoryDetails) {
          setEditCategory(categoryDetails);
          setEditShowModal(true);
        } else {
          console.error("Category with id", id, "not found");
        }
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 300 * 1024; // 300KB in bytes

    if (file && file.size <= maxSize) {
      setImageFile(file);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 300KB");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/variantmaster.php"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch variant options");
      }
      const data = await response.json();
      setVariantOptions(data);
    } catch (error) {
      console.error("Error fetching variant options:", error);
    }
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");

      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setHeadMasterView(data.permission.headMasterView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  const handleVarint = (e) => {
    setVariant(e.target.value);
  };
  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedVariants([...selectedVariants, value]);
    } else {
      setSelectedVariants(
        selectedVariants.filter((variant) => variant !== value)
      );
    }
  };

  const handleFilterChange1 = (e) => {
    setEditCategory({ ...editCategory, filter: e.target.value });
  };
  const [variantss, setVariantss] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariantss(categories);
  }, [categories]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variantss.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.category_name || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variantss, query]);
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Head Category</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Head Category
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card-cat">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>

                {/* <label>Entries</label> */}
              </div>
            </div>

            {/* <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
            {headMasterView.add === true ? (
              <button className="add-category-button" onClick={handleShowModal}>
                Add Head Category
              </button>
            ) : (
              ""
            )} */}

            <div className="d-flex align-items-center ms-auto">
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
              <div style={{ padding: "10px", marginBottom: "6px" }}>
                {headMasterView.add === true ? (
                  <button
                    className="add-category-button"
                    onClick={handleShowModal}
                  >
                    Add Head Category
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="table-container text-end">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>Category Name</th> */}
                    <th
                      onClick={() => handleSortByColumn("category_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Category Name
                      {sortColumn === "category_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Variants</th> */}
                    <th
                      onClick={() => handleSortByColumn("variants")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Variants
                      {sortColumn === "variants" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Filter</th> */}
                    <th
                      onClick={() => handleSortByColumn("filter")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Filter
                      {sortColumn === "filter" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {(headMasterView.edit || headMasterView.delete) && (
                      <th style={{ textAlign: "center" }}>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((category, index) => (
                      <tr key={category.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <img
                            src={`https://arasurdigital.com/admin/image/categories_master/${category.category_image}`}
                            width={40}
                            height={40}
                            style={{ margin: "10px" }}
                          />
                          {category.category_name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {category.variants}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {category.filter}
                        </td>
                        {(headMasterView.edit || headMasterView.delete) && (
                          <td style={{ textAlign: "center" }}>
                            {headMasterView.edit && (
                              <button
                                className="btn-edit"
                                onClick={() => handelEdit(category.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {headMasterView.delete && (
                              <button
                                className="btn-delete"
                                onClick={() => handleDelete(category.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Head Category</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Category Name <i className="text-danger ">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Category Name"
                value={categoryname}
                onChange={(e) => {
                  setCategoryname(e.target.value);
                  handleInputChange(e);
                }}
              />

              <label className="bold">
                Category Image <i className="text-danger">*</i>
              </label>

              <p>Please choose a square image 300KB.</p>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {image && (
                <img src={URL.createObjectURL(image)} alt="Uploaded Image" />
              )}
              <label className="bold">
                Variant <i className="text-danger">*</i>
              </label>
              {variantOptions.map((variant) => (
                <div key={variant} className="form-check">
                  <input
                    type="checkbox"
                    className=""
                    id={variant.variant_name}
                    value={variant.variant_name}
                    checked={selectedVariants.includes(variant.variant_name)}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label m-2"
                    htmlFor={variant.variant_name}
                  >
                    {variant.variant_name}
                  </label>
                </div>
              ))}
              <div>
                <label className="bold">Filter</label>

                <div>
                  <label>
                    <input
                      type="radio"
                      name="filterOption"
                      value="optional"
                      className="m-2"
                      checked={option === "optional"}
                      onChange={handleFilterChange}
                    />
                    Optional
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="filterOption"
                      value="default"
                      className="m-2"
                      checked={option === "default"}
                      onChange={handleFilterChange}
                    />
                    Default
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header className="modal-header">
            <h5 className="modal-title">Update Category</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label className="bold">
                Category Name <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={editCategory.category_name}
                onChange={(e) => {
                  const { value } = e.target;
                  const letterAndSpaceOnlyValue = value.replace(
                    /[^a-zA-Z\s]/g,
                    ""
                  );
                  setEditCategory({
                    ...editCategory,
                    category_name: letterAndSpaceOnlyValue,
                  });
                }}
                placeholder="Enter Category Name"
                pattern="[a-zA-Z\s]*"
              />

              <label className="bold">
                Category Image <i className="text-danger">*</i>
              </label>
              <p>Please choose a square image 300KB.</p>
              <div>
                <input
                  className="form-control"
                  style={{ width: "80%" }}
                  type="file"
                  name="file2"
                  id="myFile2"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => {
                    handleFileInputChange(e);
                  }}
                />

                <input
                  className="form-control m-2"
                  value={editCategory.category_image}
                  type="text"
                  name="current_image"
                />
                <span id="file-name" hidden>
                  {filename}
                </span>
              </div>
              <label className="bold">
                Variant <i className="text-danger">*</i>
              </label>
              {variantOptions.map((variant) => (
                <div key={variant.id} className="form-check">
                  <input
                    type="checkbox"
                    id={variant.id}
                    value={variant.variant_name}
                    checked={editCategory.variants.includes(
                      variant.variant_name
                    )}
                    onChange={() => {
                      console.log(
                        editCategory.variants.includes(variant.variant_name)
                      );
                      handleCheckboxChangeEdit(variant.variant_name);
                    }}
                  />
                  <label
                    className="form-check-label m-2"
                    htmlFor={variant.variant_name}
                  >
                    {variant.variant_name}
                  </label>
                </div>
              ))}

              <div>
                <label className="bold">Filter</label>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="filter"
                      value="optional"
                      className="m-2"
                      checked={editCategory.filter === "optional"}
                      onChange={handleFilterChange1}
                    />
                    Optional
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="filter"
                      value="default"
                      className="m-2"
                      checked={editCategory.filter === "default"}
                      onChange={handleFilterChange1}
                    />
                    Default
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={() => handleUpdate()}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default CategoriesMaster;
