import PostData from "./PostData";
const PRIMARY_URL = "https://arasurdigital.com/admin/"; //live
// const PRIMARY_URL = "http://192.168.0.104/e_commerce_local/admin/"; //test
export default class Api {
  getAdminLogin({ usermail = "", password = "", apiPath = "admin_login.php" }) {
    return PostData(
      PRIMARY_URL + apiPath + `?usermail=${usermail}&password=${password}`
    );
  }

  adminSendNotification({
    message = "",
    notifyimage = "",
    apiPath = "admin_send_notifications.php",
  }) {
    return PostData(
      PRIMARY_URL + apiPath + `?message=${message}&notifyimage=${notifyimage}`
    );
  }

  adminOrderList({ apiPath = "admin_order_list.php" }) {
    return PostData(PRIMARY_URL + apiPath);
  }
  adminSingleOrder({
    order_id = "",
    user_id = "",
    address_id = "",
    apiPath = "admin_single_order.php",
  }) {
    return PostData(
      PRIMARY_URL +
      apiPath +
      `?order_id=${order_id}&user_id=${user_id}&address_id=${address_id}`
    );
  }

  adminDashboardOrder({ apiPath = "admin_new_order.php" }) {
    return PostData(PRIMARY_URL + apiPath);
  }

  adminNotifyOrder({ apiPath = "admin_order_notify.php" }) {
    return PostData(PRIMARY_URL + apiPath);
  }

  adminNotifyUpdate({ id = "", apiPath = "admin_notify_update.php" }) {
    return PostData(PRIMARY_URL + apiPath + `?id=${id}`);
  }
  adminGetCategory({
    apiPath = 'admin_feature_section.php'
  }) {
    return PostData(PRIMARY_URL + apiPath);
  }

  adminCatProduct({
    cat_id = '',
    apiPath = 'admin_feature_product.php'
  }) {
    return PostData(PRIMARY_URL + apiPath + `?cat_id=${cat_id}`);
  }

}
