import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import "./Units.css";
import Lottie from "react-lottie";
import swal from "sweetalert";
function Units() {
  const [showModal, setShowModal] = useState(false);
  const [unitname, setUnitname] = useState("");
  const [units, setUnits] = useState([]);
  const [unitview, setUnitView] = useState("");
  const [shortcode, setShortcode] = useState("");
  const [parentunit, setParentunit] = useState("");
  const [conversion, setConversion] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [editUnit, setEditUnit] = useState({
    unitname: "",
    shortcode: "",
    parentunit: "",
    conversion: "",
  });
  const [query, setQuery] = useState("");
  const [filteredUnits, setFilteredUnits] = useState([]);

  const Navigate = useNavigate();
  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    filterUnits();
  }, [query, units]);

  useEffect(() => {
    setCurrentPage(1);
  }, [query]);

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };
  const filterUnits = () => {
    if (!Array.isArray(units)) {
    
      return;
    }
    const result = units.filter((unit) => {
      return (
        (unit.unit_name &&
          unit.unit_name.toLowerCase().includes(query.toLowerCase())) ||
        (unit.short_code &&
          unit.short_code.toLowerCase().includes(query.toLowerCase())) ||
        (unit.parent_unit &&
          unit.parent_unit.toLowerCase().includes(query.toLowerCase())) ||
        (unit.conversion &&
          unit.conversion.toLowerCase().includes(query.toLowerCase()))
      );
    });
    setFilteredUnits(result);
  };

  const sortedFilter = [...filteredUnits].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");

      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
   
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setUnitView(data.permission.unitsView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    // Calculate the current page after changing items per page
    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };
  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleCloseModal = () => {
    setShortcode("");
    setConversion("");
    setUnits("");
    setUnitname("");
    setShowModal(false);
    
  };
  useEffect(() => {
    setLoading(true);
    fetchUnits()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const fetchUnits = async () => {
    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/addunit.php"
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        setUnits(data);
      } else {
        setUnits([]);
      }
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  const handleInsert = () => {
    // Check if any required values are missing
    if (!unitname || !shortcode || !conversion) {
      swal("Warning", "All values are required for insertion.", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("unit_name", unitname);
    formData.append("short_code", shortcode);
    // formData.append("parent_unit", parentunit);
    formData.append("conversion", conversion);

    fetch("https://arasurdigital.com/admin/addunit.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        fetchUnits();
      });
    setShortcode("");
    setConversion("");
    setUnits("");
    setUnitname("");
    setShowModal(false);
  };

  const handelEdit = async (id) => {
    try {
      // Fetch data from the API using the provided id
      const response = await fetch(`https://arasurdigital.com/admin/addunit.php?id=${id}`);
  
      // Check if the response is OK
      if (response.ok) {
        const data = await response.json();
  
        // Check if data is an array and find the specific unit
        if (Array.isArray(data)) {
          const unitDetails = data.find((item) => item.id === id);
  
          if (unitDetails) {
            // Update the state with the found unit
            setEditUnit(unitDetails);
            setEditShowModal(true);
          } else {
            console.error("Unit with id", id, "not found in fetched data");
          }
        } else {
          console.error("Fetched data is not an array");
        }
      } else {
        console.error("Failed to fetch unit, status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching unit:", error);
    }
  };
  
  const handleUpdate = async () => {
    try {
      // Validate inputs
      if (!editUnit.unit_name || !editUnit.short_code || !editUnit.conversion) {
        swal("Warning", "All values are required for insertion.", "warning");
        return;
      }
  
      // Prepare the data as a JSON object
      const payload = {
        id: editUnit.id,
        unit_name: editUnit.unit_name,
        short_code: editUnit.short_code,
        conversion: editUnit.conversion,
      };
  
      // Make the PUT request with JSON body
      const response = await fetch(
        `https://arasurdigital.com/admin/addunit.php?id=${editUnit.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      // Check if the response is successful
      if (response.ok) {
        const data = await response.json();
        if (data.success === "1") {
          setUnits(units.map((unit) => (unit.id === editUnit.id ? editUnit : unit)));
          setEditShowModal(false);
        } else {
          swal("Error", data.message || "Failed to update the unit", "error");
        }
      } else {
        const errorText = await response.text();
        console.error("Failed to update unit:", errorText);
        swal("Error", "Failed to update the unit", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "An unexpected error occurred.", "error");
    }
  };
  

  const handelDelete = async (unitId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this category!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/addunit.php?id=${unitId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setUnits(units.filter((unit) => unit.id !== unitId));
          swal("Deleted!", "Units has been deleted.", "success");
        } else {
          console.error("Failed to delete unit");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [sortOrder, setSortOrder] = useState("asc");
  const [variant, setVariant] = useState([]);

  useEffect(() => {
    setVariant(units);
  }, [units]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    if (Array.isArray(variant)) {
      const updatedFilteredCapacities = variant.filter((capacity) =>
        capacity.unit_name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredCapacities(updatedFilteredCapacities);
    } else {
      console.error("variant is not an array:", variant);
    }
  }, [variant, query]);

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Manage Units</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Units
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                {/* <label>Entries</label> */}
              </div>
            </div>
            {/* <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
            {unitview.add === true ? (
              <button className="add-category-button" onClick={handleShowModal}>
                Add Units
              </button>
            ) : (
              ""
            )} */}

            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "10px" }}
            >
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>

              <div style={{ padding: "10px", marginBottom: "6px" }}>
                {unitview.add === true ? (
                  <button
                    className="add-category-button"
                    onClick={handleShowModal}
                  >
                    Add Units
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>Name</th> */}
                    <th
                      onClick={() => handleSortByColumn("unit_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Name
                      {sortColumn === "unit_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>

                    {/* <th style={{ textAlign: "center" }}>Short Code</th> */}
                    <th
                      onClick={() => handleSortByColumn("short_code")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Short Code
                      {sortColumn === "short_code" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Conversion</th> */}
                    <th
                      onClick={() => handleSortByColumn("conversion")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Conversion
                      {sortColumn === "conversion" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {unitview.edit === true || unitview.delete === true ? (
                      <th style={{ textAlign: "center" }}>Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((unit, index) => (
                      <tr key={unit.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {unit.unit_name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {unit.short_code}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {unit.conversion}
                        </td>

                        {(unitview.edit || unitview.delete) && (
                          <td
                            className="button-container"
                            style={{ textAlign: "center" }}
                          >
                            {unitview.edit && (
                              <button
                                className="btn-edit"
                                onClick={() => handelEdit(unit.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {unitview.delete && (
                              <button
                                className="btn-delete"
                                onClick={() => handelDelete(unit.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add Unit</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Unit Name <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={unitname}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setUnitname(inputValue);
                  }
                }}
                placeholder="Enter Unit Name"
              />

              <label className="bold">
                Short Code <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Short Code"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setShortcode(inputValue);
                  }
                }}
                value={shortcode}
              />

              <label className="bold">
                Conversion <i className="text-danger">*</i>
              </label>

              <input
                className="form-control"
                value={conversion}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setConversion(inputValue);
                  }
                }}
                placeholder="Enter Conversion"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Edit Unit</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Unit Name <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={editUnit.unit_name}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setEditUnit({
                      ...editUnit,
                      unit_name: inputValue,
                    });
                  }
                }}
                placeholder="Enter Unit Name"
              />

              <label className="bold">
                Short Code <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Short Code"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setEditUnit({
                      ...editUnit,
                      short_code: inputValue,
                    });
                  }
                }}
                value={editUnit.short_code}
              />

              <label className="bold">
                Conversion <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={editUnit.conversion}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setEditUnit({
                      ...editUnit,
                      conversion: inputValue,
                    });
                  }
                }}
                placeholder="Enter Conversion"
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Units;
