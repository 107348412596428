import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/esm/ModalBody";
import ModalFooter from "react-bootstrap/esm/ModalFooter";
import Button from "react-bootstrap/Button";
import Api from "../../Global/Api";
import { Alert } from "bootstrap";

function SendNotification () {
  const [showModal, setShowModal] = useState(true);
  const [image, setImage] = useState(false);
  const [notificationType, setNotificationType] = useState("Text");
  const [notification, setNotification] = useState("");
  const [message, setMessage] = useState("");
  const [notifyImage, setNotifyImage] = useState("");
  console.log(notifyImage)
  const CallApi = new Api();
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleImage = () => {
    setImage(true);
  };
  const handleTypeChange = (event) => {
    setNotificationType(event.target.value); // Update notification type based on selection
    if (event.target.value === "Image") {
      setImage(true); // Show image input if image type is selected
    } else {
      setImage(false); // Hide image input if text type is selected
    }
  };
  const sentNotification = () => {
    try {
      const formData = new FormData();
      const img = formData.append('img', notifyImage)
      CallApi.adminSendNotification({ message: message, notifyimage: notifyImage }).then((res) => {
        if (res.success === 1) {
          alert("Notification sent successfully");
        } else {
          alert("Notification sent Failed, Please Try Again");
        }
      })
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">Add Notification</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <ModalBody>
          <div className="form_1">
            <label>Type</label>
            <select className="form-select" onChange={handleTypeChange}>
              <option value="Text">Text</option>
              <option value="Image">Image</option>
            </select>
          </div>
          <div className="form_1">
            <label>Title</label>
            <input
              type="text"
              placeholder="Enter Title"
              className="form-control"
            />
          </div>
          <div className="form_1">
            <label>Message</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Notification Message Here!"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          {image && (
            <div>
              <input className="form-select" type="file" onChange={(e) => setNotifyImage(e.target.files[0])}></input>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={sentNotification}>
            SEND
          </Button>
          <Button variant="secondary">CANCEL</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SendNotification;
