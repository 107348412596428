import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import swal from "sweetalert";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import animationData from "../Assets/Animation - 1716197548567.json";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import "./Systemusers.css";
import { Search } from "lucide-react";
function Systemusers() {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [RoleName, setRoleName] = useState("");
  const [systemuserView, setSystemuserView] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [editUser, setEditUser] = useState({
    id: "",
    username: "",
    email: "",
    role: "",
    password: "",
    confirm_password: "",
  });
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(filter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filter.length / itemsPerPage);

  const visiblePages = 3;

  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  const handleCloseModal = () => {
    setUserName("");
    setEmail("");
    setRole("");
    setPassword("");
    setConfirmPassword("");
    setShowModal(false);
    setEditShowModal(false);
  };

  useEffect(() => {
    setLoading(true);
    GetData();
    GetRole()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    const result = user.filter((user) => {
      return user.username.toLowerCase().includes(query.toLowerCase());
    });
    setFilter(result);
  }, [query, user]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInsert = () => {
    if (!username || !email || !role || !password || !confirmPassword) {
      swal("warning", "Please enter required details", "warning");
      return;
    }

    if (!validateEmail(email)) {
      swal("warning", "Invalid email address.", "warning");
      return;
    }
    if (password !== confirmPassword) {
      swal("warning", "Passwords do not match.", "warning");
      return;
    }
    const formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("role", role);
    formData.append("password", password);
    formData.append("confirm_password", confirmPassword);
    fetch("https://arasurdigital.com/admin/systemuser.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text(), GetData())
      .then((data) => {
        GetData();
        setUserName("");
        setEmail("");
        setRole("");
        setPassword("");
        setConfirmPassword("");
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setShowModal(false);
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");

      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setSystemuserView(data.permission.SystemView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const GetData = async () => {
    const data = await fetch("https://arasurdigital.com/admin/systemuser.php");
    const response = await data.json();
    setUser(response);
  };
  const GetRole = async () => {
    const data = await fetch("https://arasurdigital.com/admin/role.php");
    const response = await data.json();
    setRoleName(response);
  };
  const handelDelete = async (userId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this user!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });
      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/systemuser.php?id=${userId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setUser(user.filter((u) => u.id !== userId));
          swal("Deleted!", "User has been deleted.", "success");
        } else {
          console.error("Failed to delete user");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = value.replace(/[^a-zA-Z\s]/g, "");
    setUserName(newValue);
  };

  const handleEdit = async (userId) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/systemuser.php?id=${userId}`
      );

      if (response.ok) {
        const user = await response.json();
        const userDetails = user.find((item) => item.id === userId);
        setEditUser(userDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch user");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      if (editUser.password !== editUser.confirm_password) {
        swal("warning", "Passwords do not match.", "warning");
        return;
      }

      if (!validateEmail(editUser.email)) {
        swal("warning", "Invalid email address.", "warning");
        return;
      }
      if (editUser.password !== editUser.confirm_password) {
        swal("warning", "Passwords do not match.", "warning");
        return;
      }
      const formData = new FormData();
      formData.append("id", editUser.id);
      formData.append("username", editUser.username);
      formData.append("email", editUser.email);
      formData.append("role", editUser.role);
      formData.append("password", editUser.password);
      formData.append("confirm_password", editUser.confirm_password);

      const response = await fetch(
        `https://arasurdigital.com/admin/systemuserupdate.php?id=${editUser.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        setUser(
          user.map((u) => (u.id === editUser.id ? { ...u, ...editUser } : u))
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    GetData();
  };
  const [variant, setVariant] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(user);
  }, [user]);
  const Navigate = useNavigate();

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.username || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, query]);
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">System Users</h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
            <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                  </li>{" "}
              <li aria-current="page" className="breadcrumb-item active">
                System Users
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat">
        <div className="cat-left"></div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
            </div>
          </div>

          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => handleInput(e)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
          {systemuserView.add === true ? (
            <button className="add-category-button" onClick={handleShowModal}>
              Add System Users
            </button>
          ) : (
            ""
          )} */}

          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "10px" }}
          >
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => handleInput(e)}
              />
              <FaSearch className="search-icon" />
            </div>

            <div style={{ padding: "10px", marginBottom: "6px" }}>
              {systemuserView.add === true ? (
                <button
                  className="add-category-button"
                  onClick={handleShowModal}
                >
                  Add System Users
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <div className="table-container text-end mt-3">
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>

                    <th
                      onClick={() => handleSortByColumn("username")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      User Name
                      {sortColumn === "username" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Email</th> */}
                    <th
                      onClick={() => handleSortByColumn("email")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Email
                      {sortColumn === "email" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>

                    <th
                      onClick={() => handleSortByColumn("role")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Role
                      {sortColumn === "role" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Password</th> */}
                    <th
                      onClick={() => handleSortByColumn("password")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Password
                      {sortColumn === "password" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* 
                    <th style={{ textAlign: "center" }}>Confirm Password</th> */}
                    <th
                      onClick={() => handleSortByColumn("confirm_password")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Confirm Password
                      {sortColumn === "confirm_password" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {systemuserView.edit === true ||
                    systemuserView.delete === true ? (
                      <th style={{ textAlign: "center" }}>Actions</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.map((user, index) => (
                    <tr key={user.id}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>{user.username}</td>
                      <td style={{ textAlign: "center" }}>{user.email}</td>
                      <td style={{ textAlign: "center" }}>{user[0].role}</td>
                      <td style={{ textAlign: "center" }}>{user.password}</td>
                      <td style={{ textAlign: "center" }}>
                        {user.confirm_password}
                      </td>
                      {systemuserView.edit === true ||
                      systemuserView.delete === true ? (
                        <td style={{ textAlign: "center" }}>
                          {systemuserView.edit === true ? (
                            <button
                              className="btn-edit"
                              onClick={() => handleEdit(user.id)}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </button>
                          ) : (
                            ""
                          )}
                          {systemuserView.delete === true ? (
                            <button
                              className="btn-delete"
                              onClick={() => handelDelete(user.id)}
                            >
                              <FaTrash />
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add User</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <form>
              <div className="form-group">
                <label className="bold">
                  User Name <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter User Name"
                  value={username}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className="form-group">
                <label className="bold">
                  Email <i className="text-danger">*</i>
                </label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="bold">
                  Role <i className="text-danger">*</i>
                </label>

                <select
                  className="form-select me-2"
                  value={role} // Make sure `role` here is the selected role name, not the object
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="">Select a role</option>
                  {RoleName &&
                    RoleName.map((roleObj, index) => (
                      <option key={index} value={roleObj.id}>
                        {roleObj.role}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <label className="bold">
                  Password <i className="text-danger">*</i>
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter  password"
                    value={password}
                    maxLength={6}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary font-bold"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>

              <div className="form-group">
                <label className="bold">
                  Confirm Password <i className="text-danger">*</i>
                </label>
                <div className="input-group">
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Confirm password"
                    value={confirmPassword}
                    maxLength={6}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary font-bold"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    <FontAwesomeIcon
                      icon={confirmPasswordVisible ? faEyeSlash : faEye}
                    />
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Edit User</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <form>
              <div className="form-group">
                <label>
                  User Name <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={editUser.username}
                  onChange={(e) => {
                    const filteredValue = e.target.value.replace(
                      /[^a-zA-Z\s]/g, // Allowing spaces along with alphabets
                      ""
                    );
                    setEditUser({ ...editUser, username: filteredValue });
                  }}
                />
              </div>
              <div className="form-group">
                <label>
                  Email <i className="text-danger">*</i>
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={editUser.email}
                  onChange={(e) =>
                    setEditUser({ ...editUser, email: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>
                  Role <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={editUser.role} // Set value to editUser.role
                  onChange={(e) =>
                    setEditUser({ ...editUser, role: e.target.value })
                  }
                >
                  <option value="">Select a role</option>
                  {RoleName &&
                    RoleName.map((roleObj) => (
                      <option key={roleObj.id} value={roleObj.id}>
                        {roleObj.role}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <label>
                  Password <i className="text-danger">*</i>
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    value={editUser.password}
                    onChange={(e) =>
                      setEditUser({ ...editUser, password: e.target.value })
                    }
                  />
                  <button
                    type="button"
                    className="btn btn-primary font-bold"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>
              <div className="form-group">
                <label>
                  Confirm Password <i className="text-danger">*</i>
                </label>
                <div className="input-group">
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    className="form-control"
                    value={editUser.confirm_password}
                    onChange={(e) =>
                      setEditUser({
                        ...editUser,
                        confirm_password: e.target.value,
                      })
                    }
                  />
                  <button
                    type="button"
                    className="btn btn-primary font-bold"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    <FontAwesomeIcon
                      icon={confirmPasswordVisible ? faEyeSlash : faEye}
                    />
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Systemusers;
