import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./Adddelivery.css";

function Adddelivery() {
    const [formData, setFormData] = useState({
        delivery_boy_name: "",
        dob: "",
        mobile_no: "",
        email_id: "",
        password: "",
        confirm_password: "",
        address: "",
        id_proof: "",
        bank_Name: "",
        Account_Name: "",
        Bank_Account_Number: "",
        city: "",
        Other_Payment_Information: "",
        image: null,
    });

    const Navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        try {
            const response = await fetch(
                "https://arasurdigital.com/admin/deliveryboy.php",
                {
                    method: "POST",
                    body: formDataToSend,
                }
            );
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="main-container">
            <div className=" ">
                <div className="flex-container pt-5">
                    <h3 className="head-fon-mv">Create Delivery Boy</h3>
                    <div class="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            class="breadcrumb-header float-start float-lg-end"
                        >
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a onClick={() => Navigate("/dashboard")}>
                                        Dashboard/ Manage Delivery Boy / Create Delivery Boy
                                    </a>
                                </li>{" "}
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="card ">
                    <div className="cat-left_1">
                        <h4>Delivery Boy</h4>
                        <button className="save-category-button ">
                            View Delivery Boys
                        </button>
                    </div>
                    <div>
                        <p>
                            Required fields <i className="text-danger">*</i>{" "}
                        </p>
                    </div>
                    <div className="text-center">New Delivery Boy Register Form</div>

                    <form id="deliveryForm" onSubmit={handleSubmit}>
                        <div className="form_0">
                            <div className="form_1">
                                <label>
                                    Name<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="delivery_boy_name"
                                    value={formData.delivery_boy_name}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form_1">
                                <label>
                                    Date of Birth<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dob"
                                    value={formData.dob}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form_1">
                                <label>
                                    Mobile.No<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="mobile_no"
                                    value={formData.mobile_no}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form_0">
                            <div className="form_1">
                                <label>
                                    Email<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email_id"
                                    value={formData.email_id}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form_1">
                                <label>
                                    Password<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form_1">
                                <label>
                                    Confirm Password<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="confirm_password"
                                    value={formData.confirm_password}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form_0">
                            <div className="form_1">
                                <label>
                                    Bank's IFSC code<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="id_proof"
                                    value={formData.id_proof}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form_1">
                                <label>
                                    Bank Name<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="bank_Name"
                                    value={formData.bank_Name}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form_1">
                                <label>
                                    Account Name<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="Account_Name"
                                    value={formData.Account_Name}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form_2">
                            <div className="form_1">
                                <label>
                                    Bank Account Number<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="Bank_Account_Number"
                                    value={formData.Bank_Account_Number}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form_1">
                                <label>
                                    Select or Search City<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form_2">
                            <div className="form_1">
                                <label>
                                    Address<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form_1">
                                <label>
                                    Other Payment Information<i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="Other_Payment_Information"
                                    value={formData.Other_Payment_Information}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form_1">
                            <label>
                                Image<i className="text-danger">*</i>
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                id="image"
                                name="image"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </div>
                        <div className="btn_vars">
                            <Button variant="success" type="submit">
                                Save
                            </Button>
                            <Button variant="danger">Clear</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Adddelivery;
