import React, { useEffect, useState } from "react";
import "./Managesection.css";
import { LuRefreshCcw } from "react-icons/lu";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/esm/ModalBody";
import ModalFooter from "react-bootstrap/esm/ModalFooter";
import Button from "react-bootstrap/Button";
import Api from "../../Global/Api";
import Select from "react-select";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";
function Managesection() {
  const [section, setSection] = useState([]);
  const [title, setTitle] = useState("");
  const [shortdescription, setShortdescription] = useState("");
  const [producttypes, setProducttypes] = useState("");
  const [productid, setProductid] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredSections, setFilteredSections] = useState(section || []);

  const [categoryid, setCategoryid] = useState("");
  const [filter, setFilter] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState([]);

  const [product, setProduct] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [color, setColor] = useState("");
  const [banner, setBanner] = useState("");
  const [EditshowModal, setEditShowModal] = useState(false);
  const [description, setDescription] = useState("");
  const [editSectionId, setEditSectionId] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const callApi = new Api();
  const [editFormData, setEditFormData] = useState({
    title: "",
    description: "",
    color: "",
    image: null,
    categoryId: "",
    productId: "",
  });
  console.log("first,", editFormData);
  useEffect(() => {
    GetData();
    handleGetCategory();
  }, []);

  const handleGetCategory = () => {
    callApi.adminGetCategory({}).then((cat) => {
      const categoryOptions = cat.map((catItem) => ({
        value: catItem.id,
        label: catItem.category_name,
      }));
      setCategory(categoryOptions);
    });
  };
  const getCategoryLabels = (cat_id) => {
    let categoryIds;

    try {
      // Attempt to parse cat_id as JSON
      categoryIds = JSON.parse(cat_id);
    } catch (error) {
      // If parsing fails, fall back to splitting the string
      categoryIds = typeof cat_id === "string" ? cat_id.split(",") : [cat_id];
    }

    // Ensure categoryIds is an array of strings
    categoryIds = categoryIds.map((id) => id.toString());

    return category
      .filter((item) => categoryIds.includes(item.value.toString()))
      .map((filteredItem) => filteredItem.label)
      .join(", ");
  };

  const getCategoryProduct = (pro_id) => {
    let categoryIds;

    try {
      // Attempt to parse cat_id as JSON
      categoryIds = JSON.parse(pro_id);
    } catch (error) {
      // If parsing fails, fall back to splitting the string
      categoryIds = typeof pro_id === "string" ? pro_id.split(",") : [pro_id];
    }

    // Ensure categoryIds is an array of strings
    categoryIds = categoryIds.map((id) => id.toString());

    return product
      .filter((item) => categoryIds.includes(item.value.toString()))
      .map((filteredItem) => filteredItem.label)
      .join(", ");
  };

  const handleProductGet = async (selectedOptions) => {
    const ids = selectedOptions.map((item) => item.value);
    setSelectedCat(ids);
    callApi.adminCatProduct({ cat_id: ids }).then((prod) => {
      if (prod.Success === 1) {
        const productOptions = prod.data.map((prodItem) => ({
          value: prodItem.id,
          label: prodItem.product_name,
        }));
        setProduct(productOptions);
      } else {
        setProduct([{ value: 0, label: "No Items Found" }]);
      }
    });
  };

  const handleProductSelect = (selectedOptions) => {
    setSelectedProduct(selectedOptions);
    const productIds = selectedOptions.map((item) => item.value);
   
    setProductid(productIds);
  
  };

  const handleISelectImage = (e) => {
    setBanner(e.target.files[0]);
  };

  useEffect(() => {
    const result = section.filter((sec) => {
      const titleMatch = sec.title.toLowerCase().includes(query.toLowerCase());
      const descriptionMatch = sec.description
        .toLowerCase()
        .includes(query.toLowerCase());
      const colorMatch = sec.color.toLowerCase().includes(query.toLowerCase());
      const categoryIdMatch = sec.cat_id.toString().includes(query);
      const productIdMatch = sec.pro_id.toString().includes(query);

      return (
        titleMatch ||
        descriptionMatch ||
        colorMatch ||
        categoryIdMatch ||
        productIdMatch
      );
    });

    setFilteredSections(result);
  }, [query, section]);

  const handleInsert = () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", shortdescription);
    // formData.append("cat_id", selectedCat.join([,]));

    // formData.append("pro_id", productid);
    formData.append("cat_id", JSON.stringify(selectedCat));
    formData.append("pro_id", JSON.stringify(productid));
    formData.append("color", color);
    if (banner) {
      formData.append("image", banner);
    }

    fetch("https://arasurdigital.com/admin/admin_add_feature_content.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
   
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setShowModal(false);
  };

  const handleEditChange = (e) => {
    const { name, value, files } = e.target || {};
    if (name) {
      setEditFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files ? files[0] : value,
      }));
    }
  };

  const handleEditClick = (id) => {
    setEditSectionId(id);
    const sectionToEdit = section.find((s) => s.id === id);
    if (sectionToEdit) {
      setEditFormData({
        id: sectionToEdit.id,
        title: sectionToEdit.title,
        description: sectionToEdit.description,
        color: sectionToEdit.color,
        categoryId: sectionToEdit.cat_id,
        productId: sectionToEdit.pro_id,
        image: null,
      });

      setEditShowModal(true);
    }
  };

  const handelDelete = async (Id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this feature_content!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/admin_add_feature_content.php?id=${Id}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          await GetData();
          swal("Deleted!", "feature_content has been deleted.", "success");
        } else {
          console.error("Failed to delete category");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const handleUpdate = () => {
    const formData = new FormData();
    formData.append("id", editFormData.id);
    formData.append("title", editFormData.title);
    formData.append("description", editFormData.description);
    formData.append("color", editFormData.color);
    if (editFormData.image) {
      formData.append("image", editFormData.image);
    }

    fetch("https://arasurdigital.com/admin/admin_update_section.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Failed to update");
        }
      })
      .then((data) => {
     
        if (data.status === "success") {
          GetData(); // Refresh the data
          setEditShowModal(false);
        }
        alert("Updated successfully");
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while sending data: " + error.message);
      });
  };

  const GetData = async () => {
    const data = await fetch(
      "https://arasurdigital.com/admin/admin_add_feature_content.php"
    );
    const response = await data.json();
    setSection(response);

  };
  const handleInput = (e) => {
    setQuery(e.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setColor("");
    setShortdescription("");
    setTitle("");
    setBanner("");
    setCategoryid("");
    setProductid("");
  };
  // const getCategoryLabels1 = (categoryValue) => {
  //   if (!categoryValue) return [];

  //   const categoryIds =
  //     typeof categoryValue === "string"
  //       ? categoryValue.split(",")
  //       : categoryValue;
  //   return category.filter((item) =>
  //     categoryIds.includes(item.value.toString())
  //   );
  // };

  useEffect(() => {
    if (editFormData) {
      const initialProducts = getOptionsFromIds(editFormData.productId);
      const initialCategories = getOptionsFromIds(editFormData.categoryId);

      setSelectedProduct(initialProducts);
      setSelectedCat(initialCategories);
    }
  }, [editFormData]);

  const getOptionsFromIds = (options, ids) => {
    if (!ids) return []; // Handle undefined or null ids

    const idArray = Array.isArray(ids) ? ids : ids.split(",");
    return options.filter((option) =>
      idArray.includes(option.value.toString())
    );
  };

  return (
    <div className="main-container_1">
      <div className="Order-head">
        <h3>Featured Section </h3>
        <p>Dashboard / Featured Section </p>
      </div>

      <div className="card-cat">
        <div className="form-fm">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
          </div>
          <div className="od_items_69"></div>
          <div className="ffff">
            {/* <LuRefreshCcw className="refresh" /> */}
            <div>
              <input
                type="text"
                placeholder="Search"
                className="form-control"
                onChange={handleInput}
              />
            </div>
            <div>
              <button className="o_btn" onClick={() => setShowModal(true)}>
                Add Sections
              </button>
            </div>
          </div>
        </div>
        <div className="table-container text-end mt-3">
          <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>O.ID</th>
                <th style={{ textAlign: "center" }}>Title</th>
                <th style={{ textAlign: "center" }}>Description</th>
                <th style={{ textAlign: "center" }}>Color</th>
                <th style={{ textAlign: "center" }}>Image</th>
                <th style={{ textAlign: "center" }}>Category </th>
                <th style={{ textAlign: "center" }}>Product </th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredSections &&
                filteredSections.map((sections, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "center" }}>{sections.title}</td>
                    <td style={{ textAlign: "center" }}>
                      {sections.description}
                    </td>
                    <td style={{ textAlign: "center" }}>{sections.color}</td>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={`https://arasurdigital.com/admin/image/feature/${sections.image}`}
                        width={40}
                        height={40}
                        style={{ margin: "10px" }}
                        alt={sections.title}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {getCategoryLabels(sections.cat_id)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {sections?.product_details
                        ?.map((product) => product.product_name)
                        .join(", ")}
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <button
                        className="btn-edit"
                        onClick={() => handleEditClick(sections.id)}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        className="btn-delete"
                        onClick={() => handelDelete(sections.id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header className="modal-header">
          <h5 className="modal-title">
            Create / Manage featured products section
          </h5>
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>
        <ModalBody>
          <div className="form_1">
            <label className="bold">Title for section</label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex: Featured Products /Products on Sale"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div className="form_1">
            <label className="bold">Short Description</label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex: Weekends deal goes here"
              onChange={(e) => setShortdescription(e.target.value)}
              value={shortdescription}
            />
          </div>
          <div className="form_1">
            <label className="bold">Color</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter the colour"
              onChange={(e) => setColor(e.target.value)}
              value={color}
            />
          </div>
          <div className="form_1">
            <label className="bold">Banner</label>
            <input
              multiple
              type="file"
              className="form-control"
              placeholder="Ex: Add Images here"
              onChange={handleISelectImage}
            />
          </div>
          <div className="form_1">
            <label className="bold">Category Name</label>
            <Select
              options={category}
              className="form-control"
              placeholder="Select"
              onChange={handleProductGet}
              isMulti
            />
          </div>
          <div className="form_1">
            <label className="bold">Product</label>
            <Select
              options={product}
              className="form-control"
              placeholder="Select Products"
              onChange={handleProductSelect}
              isMulti
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
        <header className="modal-header">
          <h5 className="modal-title">Edit Section</h5>
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={() => setEditShowModal(false)}
          >
            ×
          </button>
        </header>
        <ModalBody>
          <div className="form_1">
            <label>Title for section</label>
            <input
              type="text"
              className="form-control"
              name="title"
              placeholder="Ex: Featured Products /Products on Sale"
              onChange={handleEditChange}
              value={editFormData.title}
            />
          </div>
          <div className="form_1">
            <label>Description</label>
            <input
              type="text"
              className="form-control"
              name="description"
              placeholder="Description"
              onChange={handleEditChange}
              value={editFormData.description}
            />
          </div>
          <div className="form_1">
            <label>Color</label>
            <input
              type="text"
              className="form-control"
              name="color"
              placeholder="Ex: #f5f"
              onChange={handleEditChange}
              value={editFormData.color}
            />
          </div>
          <div className="form_1">
            <label>Banner</label>
            <input
              type="file"
              className="form-control"
              name="image"
              placeholder="Ex: Add Images here"
              onChange={handleEditChange}
            />
          </div>
          <div className="form_1">
            <label>Category Name</label>
            <Select
              options={category}
              value={selectedCat}
              className="form-control"
              placeholder="Select"
              onChange={handleProductGet}
              isMulti
            />
          </div>

          <div className="form_1">
            <label>Product</label>
            <Select
              options={product}
              value={selectedProduct}
              className="form-control"
              placeholder="Select Products"
              onChange={handleProductSelect}
              isMulti
            />
          </div>
        </ModalBody>
        <Modal.Footer>
          <Button variant="success" onClick={handleUpdate}>
            Update
          </Button>
          <Button variant="secondary" onClick={() => setEditShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Managesection;
