import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
function Deliverycharge() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [deliverycharge, setDeliverycharge] = useState([]);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedChargeType1, setSelectedChargeType1] = useState("");
  const [selectedChargeType, setSelectedChargeType] = useState("");
  const [km, setKm] = useState("");
  const [kmCharges, setKmCharges] = useState("");
  const [value, setValue] = useState("");
  const [valueCharges, setValueCharges] = useState("");
  const [editBrand, setEditBrand] = useState({
    id: "",
    chargeperkm: "",
    chargepervalueunit: "",
    distance: "",
    distancecharge: "",
    value: "",
    valuecharge: "",
  });
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setKm("");
    setKmCharges("");
    setValue("");
    setValueCharges("");
    setSelectedChargeType("");
  };
  const handleCloseModal1 = () => {
    setEditShowModal(false);
  };
  const handleRadioChange = (event) => {
    setSelectedChargeType(event.target.value);
    setKm("");
    setKmCharges("");
    setValue("");
    setValueCharges("");
  };

  const Getdata = async () => {
    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/deliverycharge.php"
      );
      const data = await response.json();
      console.log("Data received:", data); // Add this line
      if (Array.isArray(data)) {
        setDeliverycharge(data);
      } else {
        console.error("Expected an array, got:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    Getdata()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);
  const handleRadioChange1 = (e) => {
    const value = e.target.value;
    setSelectedChargeType1(value);
    setEditBrand({
      ...editBrand,
      distance: value === "distance" ? "1" : "0",
      value: value === "value" ? "1" : "0",
    });
  };
  const handleInsert = async () => {
    if (!selectedChargeType) {
      swal({
        title: "Warning",
        text: "Please select a charge type (Distance or Value).",
        icon: "warning",
        button: "OK",
      });
      return;
    }
    if (
      selectedChargeType === "distance" &&
      (km.trim() === "" || kmCharges.trim() === "")
    ) {
      swal({
        title: "warning",
        text: "Distance (km) and Charge per km should not be empty.",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if (
      selectedChargeType === "value" &&
      (value.trim() === "" || valueCharges.trim() === "")
    ) {
      swal({
        title: "warning",
        text: "Value and Charge per value unit should not be empty.",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    const deliveryChargeData = new FormData();
    deliveryChargeData.append(
      "distancecharge",
      selectedChargeType === "distance" ? 1 : 0
    );
    deliveryChargeData.append(
      "valuecharge",
      selectedChargeType === "value" ? 1 : 0
    );
    deliveryChargeData.append("distance", km.trim() === "" ? 0 : km);
    deliveryChargeData.append(
      "chargeperkm",
      kmCharges.trim() === "" ? 0 : kmCharges
    );
    deliveryChargeData.append("value", value.trim() === "" ? 0 : value);
    deliveryChargeData.append(
      "chargepervalueunit",
      valueCharges.trim() === "" ? 0 : valueCharges
    );

    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/deliverycharge.php",
        {
          method: "POST",
          body: deliveryChargeData,
        }
      );

      if (response.ok) {
        Getdata(); // Refresh the data
        handleCloseModal(); // Close the modal
        setKm("");
        setKmCharges("");
        setValue("");
        setValueCharges("");
        setSelectedChargeType("");
      } else {
        const responseData = await response.json();
        console.error("Failed to insert data:", responseData);
      }
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const deliveryChargeData1 = new FormData();
      deliveryChargeData1.append(
        "distancecharge",
        selectedChargeType1 === "distance" ? 1 : 0
      );
      deliveryChargeData1.append(
        "valuecharge",
        selectedChargeType1 === "value" ? 1 : 0
      );
      deliveryChargeData1.append(
        "distance",
        editBrand.distance.trim() === "" ? 0 : editBrand.distance
      );
      deliveryChargeData1.append(
        "chargeperkm",
        editBrand.chargeperkm.trim() === "" ? 0 : editBrand.chargeperkm
      );
      deliveryChargeData1.append(
        "value",
        editBrand.value.trim() === "" ? 0 : editBrand.value
      );
      deliveryChargeData1.append(
        "chargepervalueunit",
        editBrand.chargepervalueunit.trim() === ""
          ? 0
          : editBrand.chargepervalueunit
      );

      // Perform PUT request to PHP backend
      const response = await fetch(
        `https://arasurdigital.com/admin/deliverycharge.php?id=${editBrand.id}`,
        {
          method: "PUT",
          body: deliveryChargeData1,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Response data:", responseData);

      // Close modal after successful update
      setEditShowModal(false);

      // Optionally, you can handle further actions after successful update
      // For example, update state or show a success message
    } catch (error) {
      console.error("Error updating delivery charge:", error);
      // Handle error (show error message, etc.)
    }
  };

  const handleEdit = async (capacityId) => {
    console.log("edit", capacityId);
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/deliverycharge.php?id=${capacityId}`
      );
      const brands = await response.json();
      const categoryDetails = brands.find((item) => item.id === capacityId);
      console.log("hjdksd", categoryDetails);
      setEditShowModal(true);
      setEditBrand(categoryDetails);
      // if (response.ok) {
      //   const brands = await response.json();
      //   const categoryDetails = brands.find((item) => item.id === brandsId);
      //   setEditBrand(categoryDetails);
      //   setEditShowModal(true);
      // } else {
      //   console.error("Failed to fetch category");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/deliverycharge.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("deliverycharge deleted successfully!");
          Getdata(); // Refresh the variants after deleting
        } else {
          console.error("Failed to delete variant");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const filteredCapacities = deliverycharge.filter((capacity) =>
    capacity.distancecharge.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedFilter = [...filteredCapacities].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const [sortColumn, setSortColumn] = useState("");
  const [variant, setVariant] = useState([]);

  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(deliverycharge);
  }, [deliverycharge]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) =>
      capacity.distancecharge.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv"> Delivery Charge</h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>{" "}
              <li aria-current="page" className="breadcrumb-item active">
                Delivery Charge
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat">
        <div className="cat-left"></div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
             <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
          </div>
          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
          <button className="add-category-button" onClick={handleShowModal}>
            Add Delivery Charge
          </button> */}

          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "10px" }}
          >
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
            <button className="add-category-button" onClick={handleShowModal}>
              Add Delivery Charge
            </button>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.NO</th>
                  {/* <th style={{ textAlign: "center" }}>Distance Charge</th>
                  <th style={{ textAlign: "center" }}>Value Charge</th>
                  <th style={{ textAlign: "center" }}>Distance</th>
                  <th style={{ textAlign: "center" }}>Charge Per Km</th>
                  <th style={{ textAlign: "center" }}>Value</th>
                  <th style={{ textAlign: "center" }}>Charge Per Value Unit</th> */}
                  <th
                    onClick={() => handleSortByColumn("distancecharge")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Distance Charge
                    {sortColumn === "distancecharge" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSortByColumn("valuecharge")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Value Charge
                    {sortColumn === "valuecharge" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSortByColumn("distance")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Distance
                    {sortColumn === "distance" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSortByColumn("chargeperkm")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Charge Per Km
                    {sortColumn === "chargeperkm" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSortByColumn("value")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Value
                    {sortColumn === "value" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>{" "}
                  <th
                    onClick={() => handleSortByColumn("chargepervalueunit")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Charge Per Value Unit
                    {sortColumn === "chargepervalueunit" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredCapacitie.length > 0 ? (
                  filteredCapacitie.map((capacity, index) => (
                    <tr key={capacity.id}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {capacity.distancecharge}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {capacity.valuecharge}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {capacity.distance}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {capacity.chargeperkm}
                      </td>
                      <td style={{ textAlign: "center" }}>{capacity.value}</td>
                      <td style={{ textAlign: "center" }}>
                        {capacity.chargepervalueunit}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <button
                          className="btn-edit"
                          onClick={() => handleEdit(capacity.id)}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          className="btn-delete"
                          onClick={() => handleDelete(capacity.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add Delivery Charge</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="p-2">Distance Charge</label>
              <input
                type="radio"
                id="distance"
                name="location_type"
                value="distance"
                checked={selectedChargeType === "distance"}
                onChange={handleRadioChange}
              />
            </div>
            {selectedChargeType === "distance" && (
              <div>
                <div>
                  <label htmlFor="km" className="p-2">
                    Distance (km)
                  </label>
                  <input
                    type="text"
                    id="km"
                    className="form-control"
                    placeholder="Enter distance in km"
                    value={km}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^[a-zA-Z0-9]*$/.test(input) || input === "") {
                        setKm(input);
                      }
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="kmCharges" className="p-2">
                    Charge per km
                  </label>
                  <input
                    type="text"
                    id="kmCharges"
                    className="form-control"
                    placeholder="Enter charge per km"
                    value={kmCharges}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^[a-zA-Z0-9]*$/.test(input) || input === "") {
                        setKmCharges(input);
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div>
              <label className="p-2">Value Charge</label>
              <input
                type="radio"
                id="value"
                name="location_type"
                value="value"
                checked={selectedChargeType === "value"}
                onChange={handleRadioChange}
              />
            </div>
            {selectedChargeType === "value" && (
              <div>
                <input
                  type="text"
                  id="value"
                  className="form-control"
                  placeholder="Enter value"
                  value={value}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^[a-zA-Z0-9]*$/.test(input) || input === "") {
                      setValue(input);
                    }
                  }}
                />

                <div>
                  <label htmlFor="valueCharges" className="p-2">
                    Charge per value unit
                  </label>
                  <input
                    type="text"
                    id="valueCharges"
                    className="form-control"
                    placeholder="Enter charge per value unit"
                    value={valueCharges}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^[a-zA-Z0-9]*$/.test(input) || input === "") {
                        setValueCharges(input);
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={EditshowModal} onHide={handleCloseModal1}>
          <header className="modal-header">
            <h5 className="modal-title">Edit Delivery Charge</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal1}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="p-2">Distance Charge</label>
              <input
                type="radio"
                id="distance"
                name="location_type"
                value="distance"
                checked={selectedChargeType1 === "distance"}
                onChange={handleRadioChange1}
              />
            </div>
            {selectedChargeType1 === "distance" && (
              <div>
                <div>
                  <label htmlFor="km" className="p-2">
                    Distance (km)
                  </label>
                  <input
                    type="text"
                    id="km"
                    className="form-control"
                    placeholder="Enter distance in km"
                    value={editBrand.distancecharge}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^[a-zA-Z0-9]*$/.test(input) || input === "") {
                        setEditBrand({
                          ...editBrand,
                          distancecharge: input,
                        });
                      }
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="kmCharges" className="p-2">
                    Charge per km
                  </label>
                  <input
                    type="text"
                    id="kmCharges"
                    className="form-control"
                    placeholder="Enter charge per km"
                    value={editBrand.chargeperkm}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^[a-zA-Z0-9]*$/.test(input) || input === "") {
                        setEditBrand({
                          ...editBrand,
                          chargeperkm: input,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div>
              <label className="p-2">Value Charge</label>
              <input
                type="radio"
                id="value"
                name="location_type"
                value="value"
                checked={selectedChargeType1 === "value"}
                onChange={handleRadioChange1}
              />
            </div>
            {selectedChargeType1 === "value" && (
              <div>
                <input
                  type="text"
                  id="value"
                  className="form-control"
                  placeholder="Enter value"
                  value={editBrand.valuecharge}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^[a-zA-Z0-9]*$/.test(input) || input === "") {
                      setEditBrand({
                        ...editBrand,
                        valuecharge: input,
                      });
                    }
                  }}
                />

                <div>
                  <label htmlFor="valueCharges" className="p-2">
                    Charge per value unit
                  </label>
                  <input
                    type="text"
                    id="valueCharges"
                    className="form-control"
                    placeholder="Enter charge per value unit"
                    value={editBrand.chargepervalueunit}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^[a-zA-Z0-9]*$/.test(input) || input === "") {
                        setEditBrand({
                          ...editBrand,
                          chargepervalueunit: input,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseModal1}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Deliverycharge;
