import React from 'react'
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function Withdrawalrequest() {
    
  const Navigate = useNavigate();
  return (
    <div className="main-container mt-3">
    <div className=" ">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Withdrawal Requests</h3>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            class="breadcrumb-header float-start float-lg-end"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" class="breadcrumb-item active">
              Withdrawal Requests
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card ">
        <div className="cat-left">
       
        </div>
        <div className="search-left">
          <div className="searchbar">
            <input type="text" className="search" placeholder="Search" />
            <FaSearch className="search-icon" />
          </div>
        </div>
        <div className="table-container text-end mt-3">
          <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>ID</th>
                 <th  style={{textAlign: "center" }}>Type</th>
                 <th  style={{textAlign: "center" }}>Name</th>
                 <th  style={{textAlign: "center" }}>Amount ($)</th>
                 <th  style={{textAlign: "center" }}>Balance ($)</th>
                 <th  style={{textAlign: "center" }}>Message</th>
                 <th  style={{textAlign: "center" }}>Status</th>
                 <th  style={{textAlign: "center" }}>Edit</th>
                 <th  style={{textAlign: "center" }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                 <td style={{textAlign: "center" }}>1</td>
                 <td style={{textAlign: "center" }}>101</td>
                 <td style={{textAlign: "center" }}>Category 1</td>
                 <td style={{textAlign: "center" }}>Subtitle 1</td>
                 <td style={{textAlign: "center" }}>Subtitle 1</td>
                 <td style={{textAlign: "center" }}>
                  <img src="image1.jpg" alt="Image 1" />
                </td>
                 <td style={{textAlign: "center" }}>
                  <span className="active-item">Activated</span>
                </td>
                 <td style={{textAlign: "center" }}>
                  <button className="btn-edit">
                    <FaPen />
                  </button>
                </td>
                 <td style={{textAlign: "center" }}>
                  <button className="btn-delete">
                    <FaTrash />
                  </button>
                </td>
              </tr>
              <tr>
                 <td style={{textAlign: "center" }}>2</td>
                 <td style={{textAlign: "center" }}>102</td>
                 <td style={{textAlign: "center" }}>Category 2</td>
                 <td style={{textAlign: "center" }}>Subtitle 2</td>
                 <td style={{textAlign: "center" }}>Subtitle 1</td>
                 <td style={{textAlign: "center" }}>
                  <img src="image2.jpg" alt="Image 2" />
                </td>
                 <td style={{textAlign: "center" }}>
                  <span className="inactive-item">Deactivated</span>
                </td>
                 <td style={{textAlign: "center" }}>
                  <button className="btn-edit">
                    <FaPen />
                  </button>
                </td>
                 <td style={{textAlign: "center" }}>
                  <button className="btn-delete">
                    <FaTrash />
                  </button>
                </td>
              </tr>
              <tr>
                 <td style={{textAlign: "center" }}>3</td>
                 <td style={{textAlign: "center" }}>103</td>
                 <td style={{textAlign: "center" }}>Category 3</td>
                 <td style={{textAlign: "center" }}>Subtitle 3</td>
                 <td style={{textAlign: "center" }}>Subtitle 1</td>
                 <td style={{textAlign: "center" }}>
                  <img src="image3.jpg" alt="Image 3" />
                </td>
                 <td style={{textAlign: "center" }}>
                  <span className="active-item">Activated</span>
                </td>
                 <td style={{textAlign: "center" }}>
                  <button className="btn-edit">
                    <FaPen />
                  </button>
                </td>
                 <td style={{textAlign: "center" }}>
                  <button className="btn-delete">
                    <FaTrash />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="p-2">
          <label>Per page</label>
          <select className="form-select">
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="60">60</option>
            <option value="80">80</option>
          </select>
        </div>
        <div className="dataTables_paginate paging_simple_numbers p-3">
          <ul className="pagination justify-content-end">
            <li className="page-item">
              <button className="page-link">Previous</button>
            </li>
            <li className="page-item">
              <button className="page-link"></button>
            </li>
            <li className="page-item">
              <button className="page-link">Next</button>
            </li>
          </ul>
        </div>
      </div>
     
    </div>
  </div>
  )
}

export default Withdrawalrequest