import React, { useEffect, useState } from "react";
import "./Categoriesorder.css"
import { useNavigate } from "react-router-dom";
function Categoriesorder() {
  const Navigate = useNavigate();
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Categories Order</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Categories Order
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>Categories Order List</h4>
          </div>
          <table>
           
            <button className="save-category-button m-3">Save Order</button>
          </table>
        
        </div>
      </div>
    </div>
  );
}

export default Categoriesorder;
