import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function Offerimage() {
  const [showModal, setShowModal] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [position, setPosition] = useState("");
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/categories");
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleInsert = () => {
    if (!selectedFile || !position) {
      alert("Please select an image and enter a position.");
      return;
    }

    // Create a FormData object to handle file upload
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("position", position);

    // Perform the API call to upload the image and position
    fetch("/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header className="modal-header">
          <h5 className="modal-title">Add New Offers Images here</h5>
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <Modal.Body>
          <div>
            <p>
              Offer Image <i className="text-danger">*</i>
            </p>
            <input
              type="file"
              accept="image/*"
              className="form-control"
              onChange={handleFileChange}
            />

            <label>
              Position <i className="text-danger">*</i>
            </label>
            <select
              className="form-select"
              value={position}
              onChange={handlePositionChange}
            >
              <option value="">Select Position</option>
              <option value="top">Top</option>
              <option value="middle">Middle</option>
              <option value="bottom">Bottom</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Offerimage;
