import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Lottie from "react-lottie";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
function Faq() {
  const [showModal, setShowModal] = useState(false);
  const [faq, setFaq] = useState("");
  const [faqView, setfaqView] = useState("");
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState("");
  const [EditshowModal, setEditShowModal] = useState(false);
  const [filter, setFilter] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [editFaq, setEditFaq] = useState({});


  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [ansOptions, setAnsOptions] = useState([]);
  const [ans, setAns] = useState("");
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    GetData();
    setFilename(editFaq.faq_image);
  }, [editFaq]);

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");

      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setfaqView(data.permission.BatteryView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  const GetData = async () => {
    try {
      const data = await fetch("https://arasurdigital.com/admin/faq.php?");
      if (!data.ok) {
        throw new Error(`HTTP error! Status: ${data.status}`);
      }
      const response = await data.json();
      setCategories(response);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const result = categories.filter((category) => {
      const categoryNameMatch = category.faq
        .toLowerCase()
        .includes(query.toLowerCase());
      return categoryNameMatch;
    });
    setFilter(result);
  }, [query, categories]);

  // const handleInputChange = (e) => {
  //   const { value } = e.target;

  //   const newValue = value.replace(/[^a-zA-Z ]/g, "");
  //   setEditFaq({
  //     ...editFaq,
  //     faq_name: newValue,
  //   });
  // };

  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes

    if (selectedImage && selectedImage.size <= maxSize) {
      setImage(selectedImage);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 20MB");
    }
  };

  const handleInsert = () => {
    if (!image || !faq || ans.length === 0) {
      swal(
        "warning",
        "Category name, image, and answers are required!",
        "warning"
      );
      return;
    }

    const formData = new FormData();
    formData.append("faq", faq);
    formData.append("faq_image", image);
    formData.append("ans", ans);
    console.log({ formData: image });
    fetch("https://arasurdigital.com/admin/faq.php?", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {

        GetData();
        setAns("");
        setFaq("");
        setImage("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setShowModal(false);
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editFaq.id);
      formData.append("faq", editFaq.faq);
      formData.append("ans", editFaq.ans);
      formData.append("faq_image", imageFile); // Send updated image file
      formData.append("current_image", editFaq.faq_image);

      const response = await fetch(
        `https://arasurdigital.com/admin/faqupdate.php?id=${editFaq.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        // Handle success
        setCategories(
          categories.map((category) =>
            category.id === editFaq.id ? { ...category, ...editFaq } : category
          )
        );
        setEditShowModal(false);
        setFaq("");
        setEditFaq({ faq: "", ans: "", faq_image: "" });
        setImageFile(null);
        setFilename("");
        GetData(); // Refresh data after update
      } else {
        // Handle failure
        const errorData = await response.text();
        console.error("Failed to update FAQ:", errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this FAQ's!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/faq.php?id=${id}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setCategories(categories.filter((category) => category.id !== id));
          swal("Deleted!", "FAQ's has been deleted.", "success");
        } else {
          console.error("Failed to delete FAQ's");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  const handleEdit = async (id) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/faq.php?id=${id}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === id);
        if (categoryDetails) {
          setEditFaq({
            id: categoryDetails.id,
            faq: categoryDetails.faq,
            ans: categoryDetails.ans,
            faq_image: categoryDetails.faq_image,
          });
          setEditShowModal(true);
        } else {
          console.error("FAQ with id", id, "not found");
        }
      } else {
        console.error("Failed to fetch FAQ");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setEditFaq({ ...editFaq, faq_image: file.name }); // Update faq_image in editFaq
  };
  const [variant, setVariant] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(categories);
  }, [categories]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.faq || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, query]);
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv"> FAQ's</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  FAQ's
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card-cat">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                {/* <label>Entries</label> */}
              </div>
            </div>

            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "5px" }}
            >
              <div className="searchbar" style={{ margin: "10px" }}>
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
              <div>
                <button
                  className="add-category-button"
                  onClick={handleShowModal}
                >
                  Add FAQ's
                </button>
              </div>
            </div>
          </div>

          <div className="table-container text-end">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>FAQ's</th> */}
                    <th
                      onClick={() => handleSortByColumn("faq")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      FAQ's
                      {sortColumn === "faq" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Ans</th> */}
                    <th
                      onClick={() => handleSortByColumn("ans")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Ans
                      {sortColumn === "ans" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th style={{ textAlign: "center" }}>Image</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((faq, index) => (
                      <tr key={faq.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>{faq.faq}</td>
                        <td style={{ textAlign: "center" }}>{faq.ans}</td>
                        <td style={{ textAlign: "center" }}>
                          <img
                            src={`https://arasurdigital.com/admin/image/faq/${faq.faq_image}`}
                            width={40}
                            height={40}
                            style={{ margin: "10px" }}
                            alt="FAQ"
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <button
                            className="btn-edit"
                            onClick={() => handleEdit(faq.id)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </button>
                          <button
                            className="btn-delete"
                            onClick={() => handelDelete(faq.id)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header className="modal-header">
            <h5 className="modal-title">Add FAQ's</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                FAQ's <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter FAQ's"
                value={faq}
                onChange={(e) => {
                  setFaq(e.target.value);
                }}
              />
              <label className="bold">
                Ans <i className="text-danger">*</i>
              </label>
              <textarea
                className="form-control"
                placeholder="Enter Ans"
                value={ans}
                onChange={(e) => {
                  setAns(e.target.value);
                }}
              />
              <label className="bold">
                Image <i className="text-danger">*</i>
              </label>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {/* {image && (
                <img src={URL.createObjectURL(image)} alt="Uploaded Image" />
              )} */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Update FAQ's</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <div>
              <label>FAQ's</label>
              <input
                className="form-control"
                value={editFaq.faq}
                onChange={(e) => {
                  setEditFaq({ ...editFaq, faq: e.target.value });
                }}
                placeholder="Enter FAQ Name"
              />

              <label>
                Ans <i className="text-danger">*</i>
              </label>
              <textarea
                className="form-control"
                value={editFaq.ans}
                onChange={(e) => {
                  setEditFaq({ ...editFaq, ans: e.target.value });
                }}
                placeholder="Enter Ans"
              />
              <label>
                Image <i className="text-danger">*</i>
              </label>
              <div>
                <input
                  className="form-control"
                  style={{ width: "80%" }}
                  type="file"
                  name="file2"
                  id="myFile2"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => {
                    handleFileInputChange(e);
                  }}
                />

                <input
                  className="form-control"
                  value={editFaq.faq_image}
                  onChange={(e) => {
                    setEditFaq({ ...editFaq, faq_image: e.target.value });
                  }}
                  type="text"
                  name="current_image"
                />
                {/* <span id="file-name">{filename}</span> */}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Faq;
