import React, { useState, useEffect } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./Login.css"; // Import your CSS file
import swal from "sweetalert";
const MySwal = withReactContent(Swal);

function Login() {
  const [loginName, setLoginName] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [permission, setPermissions] = useState("");
  const [PasswordVisible, setPasswordVisible] = useState(false);

  console.log({ permission: permission });
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  localStorage.setItem("loginName", loginName);
  localStorage.removeItem("selectedOption");
  React.useEffect(() => {
    if (localStorage.getItem("isLoggedIn")) navigate("/dashboard");
  }, []);

  // Example usage

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (!loginName.trim() || !loginPassword.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please enter both username and password.",
        showConfirmButton: true,
        confirmButtonText: "Okay",
        confirmButtonColor: "#3085d6",
      });
      return;
    }

    try {
      // Fetch expiry data
      const expiryResponse = await fetch(
        "https://arasurdigital.com/admin/expiry.php"
      );
      if (!expiryResponse.ok) {
        throw new Error("Failed to fetch expiry data");
      }

      const expiryData = await expiryResponse.json();
      if (!expiryData || expiryData.length === 0) {
        throw new Error("No expiry data found");
      }

      // Get the first expiry object (assuming there's only one for now)
      const expiry = expiryData[0];
      const fromDate = new Date(expiry.from_date);
      const toDate = new Date(expiry.to_date);

      // Check if the current date is within the specified range
      const currentDate = new Date();
      if (currentDate < fromDate || currentDate > toDate) {
        Swal.fire({
          icon: "warning",
          title: "Update Required",
          text: "The software needs to be updated.",
          showConfirmButton: true,
          confirmButtonText: "Okay",
          confirmButtonColor: "#3085d6",
        });
        return; // Prevent further execution of login process
      }

      // Proceed with login process
      const response = await fetch(
        `https://arasurdigital.com/admin/Systemuserlogin.php?username=${loginName}&confirm_password=${loginPassword}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ loginName, loginPassword }),
        }
      );
      const data = await response.json();
      if (response.ok && data.success === 1) {
        await fetchPermissions(data.role);
      } else {
        showErrorAlert(data.message || "Login failed");
      }
    } catch (error) {
      console.error("Login error:", error);
      showErrorAlert("An error occurred. Please try again later.");
    }
  };

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Process the fetched data

      setPermissions(data.permission.accessAdmin);
      if (data.permission.accessAdmin === "1") {
        // User has permission

        MySwal.fire({
          icon: "success",
          title: "Login successful!",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          handleCloseSuccessMessage();
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("role", role);
          navigate("/dashboard");
        });
      } else {
        // User does not have permission
        MySwal.fire({
          icon: "error",
          title: "Access Denied",
          text: `This ${loginName} do not have permission to access this resource.`,
          showConfirmButton: true,
        });
      }
      // Example: Use the data to update your UI or further processing
      // const permissions = data.permission;
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
    navigate("/dashboard");
  };

  useEffect(() => {
    console.log("objectddd");
    localStorage.removeItem("activeItem");
    // //
  }, []);

  const showErrorAlert = (errorMessage) => {
    MySwal.fire({
      icon: "error",
      title: "Oops...",
      text: errorMessage,
    });
  };
  const toggleConfirmPasswordVisibility = () => {
    setPasswordVisible(!PasswordVisible);
  };
  return (
    <div className="main">
      <div className="container center">
        <h2 className="welcome"> Welcome</h2>
        <div className="row h-100 justify-content-center align-items-center">
          <form className="col-md-9">
            <div className="AppForm shadow-lg">
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <div className="AppFormLeft">
                    <h1 className="bold">Login</h1>
                    <div className="form-group position-relative mb-4">
                      <input
                        type="text"
                        className="input-text"
                        id="login_name"
                        style={{ color: "black", paddingRight: "40px" }}
                        placeholder="Enter User Name"
                        value={loginName}
                        onChange={(e) => setLoginName(e.target.value)}
                      />
                      <i className="fa fa-user-o"></i>
                    </div>
                    <div className="form-group position-relative mb-1">
                      <input
                        type={PasswordVisible ? "text" : "password"}
                        className="input-text"
                        id="login_password"
                        placeholder="Enter Password"
                        maxLength={8}
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                        style={{ paddingRight: "40px" }} // Extra space for the icon
                      />
                      <button
                        type="button"
                        className="password-toggle-icon"
                        onClick={toggleConfirmPasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "10",
                          margin: "0",
                          bottom: "10",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={PasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn btn-success  text-uppercase"
                        onClick={handleLoginSubmit}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="AppFormRight position-relative d-flex justify-content-center flex-column align-items-center text-center p-5 text-white">
                    <h2 className="position-relative px-4 pb-3 mb-4"></h2>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
