
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './Bulkupload.css'
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { Button } from "react-bootstrap";

function Bulkupload() {
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Bulk upload</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a >Dashboard/Bulk upload</a>
                </li>{" "}
                
              </ol>
            </nav>
          </div>
        </div>

        <div className="card ">
          <div className="cat-left">
            <h4>  Product Bulk Upload Form</h4>

            
          </div>
          <div className="search-left">
            
          </div>
          <p>
               CSV File
              </p>
              <input type="file" accept="image/*" className="form-control"></input>
              <div className="upload ">
                <Button variant="success">Upload</Button>
                <Button variant="secondary">Clear</Button>
              </div>
        </div>
       
         
      </div>
    </div>
  )
}

export default Bulkupload
