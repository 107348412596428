import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

const Setting = () => {
  const [currentColor, setCurrentColor] = useState("");

  useEffect(() => {
    const savedColor = localStorage.getItem("selectedColor");
    if (savedColor) {
      setCurrentColor(JSON.parse(savedColor));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("selectedColor", JSON.stringify(currentColor));
  }, [currentColor]);

  const handleChangeComplete = (color) => {
    setCurrentColor(color);
  };

  return (
    <div className="container mt-5 mb-5 p-5 m-5" >
      {/* <h1>Color Picker</h1>
      <h1>Color Picker with react-color</h1> */}
      <SketchPicker color={currentColor} onChangeComplete={handleChangeComplete} />
      {/* <h1> Here's your color code: {currentColor.hex} </h1> */}
    </div>
  );
};

export default Setting;
