import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import swal from "sweetalert";
function Purchase() {
  const Navigate = useNavigate();
  const [products, setProducts] = useState([]);
  console.log({ products: products });
  const [showModal, setShowModal] = useState(false);
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState("");
  const [overallPrice, setOverallPrice] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState("");
  console.log("object", selectedProduct);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedvariant_id, setSelectedvariant_id] = useState("");
  console.log("selectedvariant_id", selectedvariant_id);
  const [purchaseData, setPurchaseData] = useState([]);
  const [date, setDate] = useState("");

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setDate(currentDate);
  }, []);
  const handleQtyChange = (e) => {
    const value = e.target.value;
    const newQty = value === "" ? 0 : parseInt(value, 10);
    if (/^\d*$/.test(value)) {
      setQty(value);
    }
    setOverallPrice(newQty * price);
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    const newPrice = value === "" ? 0 : parseFloat(value);
    setPrice(value);
    setOverallPrice(qty * newPrice);
  };

  const handleVariantChange = (e) => {
    const selectedValue = e.target.value;
    const [productId, variantId] = selectedValue.split("-");
    console.log("productId", productId);
    console.log("variantId", variantId);
    setSelectedvariant_id(variantId);

    if (!products?.categories) {
      console.error("Categories data is not available.");
      return;
    }

    // Flatten the list of products from all categories
    const allProducts = products.categories.flatMap(
      (category) => category.products || []
    );
    console.log("allProducts", allProducts);
    const filteredProducts = variantId
      ? allProducts.filter(
          (product) =>
            product.variants &&
            product.variants.some((variant) => variant.id === variantId)
        )
      : allProducts.filter((product) => product.id === productId);
    console.log("ghhhhhhhhhhh", filteredProducts);
    setSelectedProduct(filteredProducts.map((product) => product.id));
    console.log("Selected Variant ID:", variantId);
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const sortedFilter = purchaseData.filter((purchase) =>
    purchase.product_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);
  const getData = () => {
    return fetch("https://arasurdigital.com/admin/purchase.php")
      .then((response) => response.json())
      .then((data) => {
        setPurchaseData(data);
      })
      .catch((error) => {
        console.error("Error fetching purchase data:", error);
        throw error; // Propagate the error to the caller
      });
  };

  useEffect(() => {
    setLoading(true);
    getData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleSubmit = () => {
    // Check if any required field is missing
    if (!selectedProduct || !overallPrice || !qty || !price || !date) {
      swal("warning", "All fields are required", "warning");
      return;
    }

    // Prepare form data for the initial purchase submission
    const formData = new FormData();
    formData.append("product_name", selectedProduct);
    formData.append("variant_id", selectedvariant_id);
    formData.append("overall_price", overallPrice);
    formData.append("qty", qty);
    formData.append("price", price);
    formData.append("date", date);

    // Submit the purchase
    fetch("https://arasurdigital.com/admin/purchase.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // Fetch current stock and price based on whether a variant is selected
        const stockUrl = selectedvariant_id
          ? `https://arasurdigital.com/admin/getVariantStockAndPrice.php?id=${selectedvariant_id}`
          : `https://arasurdigital.com/admin/getProductStock.php?id=${selectedProduct}`;

        fetch(stockUrl)
          .then((response) => response.json())
          .then((stockAndPriceData) => {
            const currentStock = stockAndPriceData.variant_stock
              ? stockAndPriceData.variant_stock
              : stockAndPriceData.closeing_stock;
            const currentPrice = stockAndPriceData.price
              ? stockAndPriceData.price
              : 0;

            // Update stock and price
            const newStock = parseInt(currentStock, 10) + parseInt(qty, 10);
            const newPrice =
              parseFloat(currentPrice) + parseFloat(overallPrice);

            const updateFormData = new FormData();
            if (selectedvariant_id) {
              updateFormData.append("variant_stock", newStock);
            } else {
              updateFormData.append("closeing_stock", newStock);
            }
            
            if (selectedvariant_id) {
              updateFormData.append("price", newPrice);
            }

            // Choose the correct URL for updating stock based on variant presence
            const updateUrl = selectedvariant_id
              ? `https://arasurdigital.com/admin/purchasetostock.php?id=${selectedvariant_id}`
              : `https://arasurdigital.com/admin/purchasetoproduct.php?id=${selectedProduct}`;

            // Perform the stock update
            fetch(updateUrl, {
              method: "POST",
              body: updateFormData,
            })
              .then((response) => response.json())
              .then((result) => {
                console.log("Records updated:", result);
                getData();
                setQty("");
                setPrice("");
                setOverallPrice("");
              })
              .catch((error) => {
                console.error("Error updating records:", error);
              });

            getData();
          })
          .catch((error) => {
            console.error("Error fetching current stock and price:", error);
          });
      })
      .catch((error) => {
        console.error("Error submitting purchase:", error);
      });

    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setQty("");
    setPrice("");
    setOverallPrice("");
    setShowModal(false);
  };

  useEffect(() => {
    // Fetch products from the API
    fetch("https://arasurdigital.com/admin/productapp.php")
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("activeItem", "dashboard");
  }, []);

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Add Purchase</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a
                    onClick={() => {
                      localStorage.setItem("activeItem", "dashboard");
                      Navigate("/dashboard");
                    }}
                  >
                    Dashboard
                  </a>
                </li>

                <li aria-current="page" className="breadcrumb-item active">
                  Add Purchase
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card-cat">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  className="form-select me-2"
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
              </div>
            </div>
            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "10px" }}
            >
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="search-icon" />
              </div>
              <div style={{ padding: "10px", marginBottom: "6px" }}>
                <button
                  className="add-category-button"
                  onClick={handleShowModal}
                >
                  Add Purchase
                </button>
              </div>
            </div>
          </div>
          <div className="table-container text-end">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    <th style={{ textAlign: "center" }}>Product Name</th>
                    <th style={{ textAlign: "center" }}>Qty</th>
                    <th style={{ textAlign: "center" }}>Price</th>
                    <th style={{ textAlign: "center" }}>Overall Price</th>
                    <th style={{ textAlign: "center" }}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.length > 0 ? (
                    currentPageData.map((purchase, index) => (
                      <tr key={purchase.purchase_id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {purchase.product_name}
                        </td>
                        <td style={{ textAlign: "center" }}>{purchase.qty}</td>
                        <td style={{ textAlign: "center" }}>
                          {purchase.price}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {purchase.overall_price}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {new Date(purchase.date)
                            .toISOString()
                            .slice(0, 10)
                            .split("-")
                            .reverse()
                            .join("-")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>

          <Modal show={showModal} onHide={handleCloseModal}>
            <header class="modal-header">
              <h5 class="modal-title">Add Purchase</h5>
              <button
                type="button"
                aria-label="Close"
                class="close"
                onClick={handleCloseModal}
              >
                ×
              </button>
            </header>

            <Modal.Body>
              <div className="form-column">
                <label className="bold">
                  Product name <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  onChange={(e) => {
                    setSelectedProduct(e.target.value);
                    handleVariantChange(e);
                  }}
                >
                  <option value="">Select Product</option>
                  {products?.categories?.map((category, catIndex) =>
                    category.products?.map((product, prodIndex) =>
                      product.variants && product.variants.length > 0 ? (
                        product.variants.map((variant, varIndex) => (
                          <option
                            key={`${catIndex}-${prodIndex}-${varIndex}`}
                            value={`${product.id}-${variant.id}`} // Assuming variant has an id
                          >
                            {product.product_name} - {variant.storage} -{" "}
                            {variant.variant_size} - {variant.colour}
                          </option>
                        ))
                      ) : (
                        <option
                          key={`${catIndex}-${prodIndex}`}
                          value={product.id}
                        >
                          {product.product_name}
                        </option>
                      )
                    )
                  )}

                  {/* {products &&
                    products.map(
                      (product) =>
                        product.variants &&
                        product.variants.map((variant) => (
                          <option key={variant.id} value={variant.id}>
                            {product.product_name} - {variant.colour},{" "}
                            {variant.storage}GB Storage, {variant.variant_size}
                            Ram
                          </option>
                        ))
                    )} */}
                </select>
                <label className="bold">
                  Product Qty <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  value={qty}
                  onChange={handleQtyChange}
                />

                <label className="bold">
                  Product Price <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  value={price}
                  onChange={handlePriceChange}
                />

                <label className="bold">
                  Overall Product Price <i className="text-danger">*</i>
                </label>
                <input className="form-control" value={overallPrice} disabled />
                <label className="bold">
                  Current Date<i className="text-danger">*</i>
                </label>
                <input
                  id="date"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="form-control"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleSubmit}>
                Save
              </Button>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`page-item ${page === currentPage ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Purchase;
