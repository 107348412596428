import React, { useEffect } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineSetting, AiOutlineLogout } from "react-icons/ai";
import { useState } from "react";
import { FaGear, FaRegMessage } from "react-icons/fa6";
import user from "../Assets/Images/profile.png";
function Header() {
  const [message, setMessage] = useState();
  const [notification, setNotification] = useState();
  const loginName = localStorage.getItem("loginName");
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const handleSettings = () => {
    navigate("/setting");
  };
  const handleNotification = () => {
    setNotification(!notification);
  };
  const handleAvatarClick = () => {
    setShowDropdown(!showDropdown);
  };
  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem("isLoggedIn");
    navigate("/");
  };

  useEffect(() => {
    if (!localStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="head_head">
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            {/* Navbar collapse section */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/* Messages dropdown */}
                {/* <div className="msg">
                <FaRegMessage className="head_img_m" onClick={handleMessage} />
                {message && (
                  <div className="dropdwn_msg">
                    <p>Message</p>
                    <p>No New Message</p>
                  </div>
                )}
              </div> */}
                <div className="msg">
                  <IoMdNotificationsOutline
                    className="head_img"
                    onClick={handleNotification}
                  />
                  {notification && (
                    <div className="dropdwn_msg_1">
                      <p>You have received new order</p>
                      <p>Order #1789 has been Payment Pending</p>
                    </div>
                  )}
                </div>

                {/* User dropdown */}
                <div className="dropdown">
                  <a
                    href="#"
                    className="nav-link"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="user-menu d-flex">
                      <div className="user-img d-flex align-items-center">
                        <div className="avatar avatar-md">
                          <img src={user} alt="User Avatar" />
                        </div>
                      </div>
                    </div>
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-lg-end custom-dropdown"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <h6 className="dropdown-header">Hello, {loginName}!</h6>
                    </li>
                    <li>
                      <div className="dropdown-log" onClick={handleSettings}>
                        <FaGear className="icon-mid me-2" /> Settings
                      </div>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <div className="dropdown-log" onClick={handleLogout}>
                        <AiOutlineLogout className="icon-mid me-2" /> Logout
                      </div>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
