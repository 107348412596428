import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Productsalesreport.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FaFileExcel } from "react-icons/fa";
import Lottie from "react-lottie";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faEye } from "@fortawesome/free-solid-svg-icons";
function Productsalesreport() {
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState([]);
  // console.log({ productDetails: productDetails });
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));

  // console.log({ selectedDate: selectedDate });
  
  // Function to format date as "DD-MM-YYYY"
  function formatDate(date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1; // getMonth() returns 0-based month, so add 1
    let day = date.getDate();
  
    // Ensure month and day are always two digits
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
  
    return `${day}-${month}-${year}`; // Return the date in "DD-MM-YYYY" format
  }
  
  // Function to handle input change in search bar
  const handleInput = (e) => {
    setSearchQuery(e.target.value);
  };

  // Fetch data from API
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/Productsalesreports.php?date=${selectedDate}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setProductDetails(data); // Update state with fetched data
      setFilteredData(data); // Initialize filtered data with fetched data
      setLoading(false);
    } catch (error) {
      console.error("Fetch error:", error);
      setLoading(false);
      // Handle errors, e.g., set an error state
    }
  };
  const handleOpenModal = (productId) => {
    fetch(`https://arasurdigital.com/admin/variant.php?id=${productId}`)
      .then((response) => response.json())
      .then((data) => {
        setModalContent(data);
        setShowModal(true);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fetch data on component mount and when selected date changes
  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  // Update filtered data when searchQuery or productDetails change
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = productDetails.filter((item) =>
      item.product_name.toLowerCase().includes(lowerCaseQuery) ||
      item.product_amt.toString().includes(lowerCaseQuery) ||  // Filter by product_amt
      item.order_id.toString().includes(lowerCaseQuery)       // Filter by order_id
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page when search query changes
  }, [searchQuery, productDetails]);


  // Pagination calculations
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Function to handle change in items per page
  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Function to generate pagination numbers
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    const visiblePages = 3;
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const [variant, setVariant] = useState([]);


  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {

    setVariant(productDetails)
  }, [productDetails]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.product_name || ''; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Product Sales Reports</h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a onClick={() => navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" className="breadcrumb-item active">
                Product Sales Reports
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="search-left-pro">
        <div className="left-content">
          <h6>Date</h6>
          <div className="clear_btn m-2">
            <input
              type="date"
              className="form-control"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="card-cat">
        <div className="">
          <div className="">

          </div>
          <div className="">
            <div className="">
              <FaFileExcel className="export-btn" />
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button export"
                table="table-to-xls"
                filename="Product Sales Reports"
                sheet="Product Sales Reports"
                buttonText="Export to Excel"
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
             <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>

            </div>
          </div>
          <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                value={searchQuery}
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
        </div>

        <div className="table-container text-end ">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  {/* <th style={{ textAlign: "center" }}>Product Name</th> */}
                  <th
                    onClick={() => handleSortByColumn("product_name")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Product Name
                    {sortColumn === "product_name" && (
                      sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "8px" }}/>
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px" }}/>
                      )
                    )}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Product Qty</th> */}
                  <th
                    onClick={() => handleSortByColumn("ProductQty")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Product Qty
                    {sortColumn === "ProductQty" && (
                      sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "8px" }}/>
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px" }}/>
                      )
                    )}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Product Amt</th> */}
                  <th
                    onClick={() => handleSortByColumn("product_amt")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Product Amt
                    {sortColumn === "product_amt" && (
                      sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "8px" }}/>
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px" }}/>
                      )
                    )}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Product Tax</th> */}
                  <th
                    onClick={() => handleSortByColumn("product_tax")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Product Tax
                    {sortColumn === "product_tax" && (
                      sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "8px" }}/>
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px" }}/>
                      )
                    )}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("order_id")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Order Id
                    {sortColumn === "order_id" && (
                      sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "8px" }}/>
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px" }}/>
                      )
                    )}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Total Sales</th> */}
                  <th
                    onClick={() => handleSortByColumn("ProductTotalPrice")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Total Sales
                    {sortColumn === "ProductTotalPrice" && (
                      sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "8px" }}/>
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px" }}/>
                      )
                    )}
                  </th>
                  {/* <th style={{ width: "4%", textAlign: "center" }}>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {filteredCapacitie.length > 0 ? (
                  filteredCapacitie.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {item.product_name}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.ProductQtytemQty}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.product_amt}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.product_tax}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.order_id}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.ProductTotalPrice}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="dataTables_paginate paging_simple_numbers p-2">
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
              }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${page === currentPage ? "active" : ""
                }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
              }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">Add Promo Code</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <Modal.Body>
          {modalContent && modalContent.length > 0 ? (
            modalContent.map((variant, index) => (
              <div key={index} className="variant-details">
                <p>
                  <span className="bold">Variant Size: </span>
                  {variant.variant_size}
                </p>
                <p>
                  <span className="bold">Price:</span> Rs : {""}
                  {variant.price}
                </p>
                <p>
                  <span className="bold">Stock:</span> {variant.variant_stock}
                </p>
                <p>
                  <span className="bold">Colour:</span> {variant.colour}
                </p>
                <p>
                  <span className="bold">Storage:</span> {variant.storage}
                </p>
                <p>
                  <span className="bold">Size:</span> {variant.variant_size}
                </p>
                <p>
                  <span className="bold">Discount Price:</span> Rs : {""}
                  {variant.discount_price}
                </p>
                <p>
                  <span className="bold">Variant Status:</span>{" "}
                  {variant.variant_status}
                </p>
                <div className="variant-images">
                  {variant.variant_image &&
                    JSON.parse(variant.variant_image).map((image, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={`https://arasurdigital.com/admin/image/variant/${image}`}
                        alt={`Variant ${imgIndex + 1}`}
                        className="variant-image"
                      />
                    ))}
                </div>
              </div>
            ))
          ) : (
            <p>No variants available...!</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Productsalesreport;
