import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import "./Layout.css";
import Header from "../Header/Header";

function Layout() {
  return (
    <div>
      <div className="content_space">
        <Sidebar />

        <Header />
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
