import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Navigate, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Lottie from "react-lottie";
import swal from "sweetalert";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import "./Taxes.css";
function Taxes() {
  const [showModal, setShowModal] = useState(false);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [tax, setTax] = useState([]);
  const [taxview, setTaxesView] = useState("");
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState("limited");
  const [editTax, setEditTax] = useState({
    tax_name: "",
    percentage: "",
    status: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState([]);

  const [status, setStatus] = useState("1");
  const [percentage, setPercentage] = useState("");
  const navigate = useNavigate();

  const handleShowModal = () => {
    setShowModal(true);
  };

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleOptionChange = (event) => {
    setStatus(event.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setLoading(true);
    GetData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleInput = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const result = Array.isArray(tax)
      ? tax.filter((item) => {
          const nameMatch =
            item.tax_name &&
            item.tax_name.toLowerCase().includes(searchQuery.toLowerCase());
          const statusMatch =
            item.status &&
            item.status
              .toString()
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          return nameMatch || statusMatch;
        })
      : [];
    setFilter(result);
  }, [searchQuery, tax]);

  const handleInsert = () => {
    if (!title || !percentage || !status) {
      swal("warning", "Please enter the details", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("tax_name", title);
    formData.append("percentage", percentage);
    formData.append("status", status);

    console.log({ status: formData.get("status") });

    fetch("https://arasurdigital.com/admin/addtax.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setTax("");
    setTitle("");
    setPercentage("");
    setShowModal(false);
  };

  const GetData = async () => {
    const data = await fetch("https://arasurdigital.com/admin/addtax.php");
    const response = await data.json();
    setTax(response);
  };

  const handleEdit = async (taxesId) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/addtax.php?id=${taxesId}`
      );
      if (response.ok) {
        const taxes = await response.json();
        const categoryDetails = taxes.find((item) => item.id === taxesId);
        setEditTax(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handelDelete = async (taxesId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this tax!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/addtax.php?id=${taxesId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setTax(tax.filter((taxes) => taxes.id !== taxesId));
          swal("Deleted!", "Tax has been deleted.", "success");
        } else {
          console.error("Failed to delete taxes");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!editTax.tax_name || !editTax.percentage || !editTax.status) {
        swal("warning", "Please enter the details", "warning");
        return;
      }
      const formData = new FormData();
      formData.append("tax_name", editTax.tax_name);
      formData.append("percentage", editTax.percentage);
      formData.append("status", editTax.status);

      const response = await fetch(
        `https://arasurdigital.com/admin/addtaxupdate.php?id=${editTax.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");
        setTax(tax.map((taxes) => (taxes.id === editTax.id ? editTax : taxes)));
        setEditShowModal(false); // Close the edit modal
        GetData(); // Get the updated data
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setTaxesView(data.permission.taxesView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  useEffect(() => {
    setVariant(tax);
  }, [tax]);

  const [sortOrder, setSortOrder] = useState("asc");
  const [variant, setVariant] = useState([]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    if (Array.isArray(variant)) {
      const updatedFilteredCapacities = variant.filter((capacity) => {
        const promoCode = capacity.tax_name || ""; // Default to an empty string if undefined
        return promoCode.toLowerCase().includes(searchQuery.toLowerCase());
      });
      setFilteredCapacities(updatedFilteredCapacities);
    } else {
      console.error("Expected variant to be an array, but received:", variant);
    }
  }, [variant, searchQuery]);

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Taxes</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Taxes
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                {/* <label>Entries</label> */}
              </div>
            </div>
            {/* <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
            {taxview.add === true ? (
              <button className="add-category-button" onClick={handleShowModal}>
                Add Taxes
              </button>
            ) : (
              ""
            )} */}

            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "10px" }}
            >
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
              <div style={{ padding: "10px", marginBottom: "6px" }}>
                {taxview.add === true ? (
                  <button
                    className="add-category-button"
                    onClick={handleShowModal}
                  >
                    Add Taxes
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>title</th> */}
                    <th
                      onClick={() => handleSortByColumn("tax_name")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Title
                      {sortColumn === "tax_name" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ textAlign: "center" }}>Percentage</th> */}
                    <th
                      onClick={() => handleSortByColumn("percentage")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Percentage
                      {sortColumn === "percentage" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    {taxview.edit === true || taxview.delete === true ? (
                      <th style={{ textAlign: "center" }}>Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((taxes, index) => (
                      <tr key={taxes.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {taxes.tax_name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {taxes.percentage}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {taxes.status === "1" ? (
                            <span className="active-item">Active</span>
                          ) : (
                            <span className="inactive-item">In-Active</span>
                          )}
                        </td>
                        {(taxview.edit || taxview.delete) && (
                          <td style={{ textAlign: "center" }}>
                            {taxview.edit && (
                              <button
                                className="btn-edit"
                                onClick={() => handleEdit(taxes.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {taxview.delete && (
                              <button
                                className="btn-delete"
                                onClick={() => handelDelete(taxes.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add Taxes</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Title <i className="text-danger ">*</i>
              </label>
              <input
                className="form-control"
                value={title}
                placeholder="Enter tax title"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters
                  setTitle(filteredValue);
                }}
              />

              <label className="bold">
                Percentage <i className="text-danger ">*</i>
              </label>
              <input
                className="form-control "
                value={percentage}
                placeholder="Enter Percentage"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const filteredValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
                  setPercentage(filteredValue);
                }}
              />
              <label className="bold">
                Status <i className="text-danger ">*</i>
              </label>
              <div className="form-switch formswitch d-flex align-items-center">
                <input
                  className={`form-check-input custom-switch w-100 ${
                    status === "1" ? "bg-success" : "bg-danger"
                  }`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={status === "1"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "1" : "0";
                    setStatus(newValue);
                    console.log(`Checkbox value: ${newValue}`);
                  }}
                />
                <label
                  htmlFor="flexSwitchCheckDefault"
                  className="status-label ms-2 mb-0"
                >
                  {status === "1" ? (
                    <span className="text-success w-20">Active</span>
                  ) : (
                    <span className="text-danger ">In-Active</span>
                  )}
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* editable */}
        <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
          <header className="modal-header">
            <h5 className="modal-title">Edit Taxes</h5>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Title <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={editTax.tax_name}
                placeholder="Enter tax title"
                onChange={(e) =>
                  setEditTax({
                    ...editTax,
                    tax_name: e.target.value.replace(/[^a-zA-Z\s]/g, ""), // Remove non-alphabetic characters
                  })
                }
              />

              <label className="bold">
                Percentage <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                value={editTax.percentage}
                placeholder="Enter Percentage"
                onChange={(e) =>
                  setEditTax({
                    ...editTax,
                    percentage: e.target.value.replace(/\D/g, ""), // Remove non-numeric characters
                  })
                }
              />

              <label className="bold">
                Status <i className="text-danger">*</i>
              </label>
              <div className="form-switch formswitch d-flex align-items-center">
                <input
                  className={`form-check-input custom-switch w-100 ${
                    editTax.status === "1" ? "bg-success" : "bg-danger"
                  }`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={editTax.status === "1"}
                  onChange={(e) => {
                    const newStatus = e.target.checked ? "1" : "0";
                    setEditTax({
                      ...editTax,
                      status: newStatus,
                    });
                  }}
                />
                <label
                  htmlFor="flexSwitchCheckDefault"
                  className="status-label ms-2 mb-0"
                >
                  {editTax.status === "1" ? (
                    <span className="text-success">Active</span>
                  ) : (
                    <span className="text-danger">In-Active</span>
                  )}
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleUpdate}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Taxes;
