import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/esm/ModalBody";
import ModalFooter from "react-bootstrap/esm/ModalFooter";
import "./Managepromo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Lottie from "react-lottie";
import swal from "sweetalert";
import { parse, format } from "date-fns";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
function Managepromo() {
  const [showModal, setShowmodal] = useState(false);
  const [promocode, setPromocode] = useState("");
  const [message, setMessage] = useState("");
  const [managepromoView, setManagepromoView] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [nouser, setNouser] = useState("");
  const [minorderamt, setOrderamnt] = useState("");
  const [distype, setDistype] = useState("");

  
  const [maxdistype, setMaxdistype] = useState("");
  const Navigate = useNavigate();
  const [image, setImage] = useState("");
  const [filter, setFilter] = useState([]);
  const [repusage, setRepusage] = useState("Select");
  const [norepusage, setNorepusage] = useState("");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [addpromo, setAddPromo] = useState([]);
  const [editpromo, setEditpromo] = useState({
    id: "",
    promo_code: "",
    message: "",
    start_date: "",
    end_date: "",
    no_user: "",
    min_orderamt: "",
    dis_type: "",
    max_distype: "",
    rep_usage: "",
    no_of_rep_usage: "",
    image: "",
  });
  console.log("data", editpromo);
  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [editShowModal, setEditShowModal] = useState(false);
  useEffect(() => {
    setLoading(true);
    GetData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setFilename(editpromo.image);
  }, []);

  const handleInsert = () => {
    // Check for all required fields
    if (
      !promocode.trim() ||
      !message.trim() ||
      !startdate.trim() ||
      !enddate.trim() ||
      !nouser.trim() ||
      !minorderamt.trim() ||
      !distype.trim() ||
      !maxdistype.trim() ||
      !image
    ) {
      swal("warning", "Please enter all the details", "warning");
      return;
    }

    // Format the start and end dates
    const formattedStartDate = formatDate(new Date(startdate));
    const formattedEndDate = formatDate(new Date(enddate));

    const formData = new FormData();
    formData.append("promo_code", promocode);
    formData.append("message", message);
    formData.append("start_date", formattedStartDate);
    formData.append("end_date", formattedEndDate);
    formData.append("no_user", nouser);
    formData.append("min_orderamt", minorderamt);
    formData.append("dis_type", distype);
    formData.append("max_distype", maxdistype);
    formData.append("rep_usage", repusage);
    formData.append("no_of_rep_usage", norepusage);
    formData.append("image", image);

    fetch("https://arasurdigital.com/admin/promocode.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData(); // Assuming this function updates your UI with the new data
        // Clear the form data
        setPromocode("");
        setMessage("");
        setStartdate("");
        setEnddate("");
        setNouser("");
        setOrderamnt("");
        setDistype("");
        setMaxdistype("");
        setRepusage("");
        setNorepusage("");
        setImage(null);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setShowmodal(false); // Assuming setShowModal is used to hide the modal after inserting data
  };

  const GetData = async () => {
    const data = await fetch("https://arasurdigital.com/admin/promocode.php");
    const response = await data.json();
    setAddPromo(response);
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  useEffect(() => {
    const result = addpromo.filter((promo) => {
      const promoCode = promo.promo_code;
      if (promoCode) {
        const parentCategoryMatch = promoCode
          .toLowerCase()
          .includes(query.toLowerCase());
        return parentCategoryMatch;
      }
      return false;
    });

    setFilter(result);
  }, [query, addpromo]);

  useEffect(() => {
    setCurrentPage(1);
  }, [query]);
  const handleCloseModal = () => {
    setShowmodal(false);
    setPromocode("");
    setMessage("");
    setStartdate("");
    setEnddate("");
    setNouser("");
    setOrderamnt("");
    setDistype("");
    setMaxdistype("");
    setRepusage("");
    setNorepusage("");
    setImage("");
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    console.log({
      role: `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
    });
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setManagepromoView(data.permission.PromoView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const handelDelete = async (addpromosId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this promocode!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/promocode.php?id=${addpromosId}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("promocode deleted successfully!");
          GetData();
        } else {
          console.error("Failed to delete promocode");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = async (addpromosId) => {
    console.log("Promo ID:", addpromosId.id);
  
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/promocode.php?id=${addpromosId.id}`
      );
  
      if (response.ok) {
        const addpromos = await response.json();
        console.log("API Response:", addpromos); // Log the full response for inspection
  
        const promoDetails = addpromos.find(
          (item) => item.id === addpromosId.id
        );
  
        if (promoDetails) {
          // Log original date strings
          console.log("Original Start Date:", promoDetails.start_date);
          console.log("Original End Date:", promoDetails.end_date);
  
          // Function to parse dates that might be in different formats
          const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split("-");
            return new Date(`${year}-${month}-${day}`);
          };
  
          let startDate = parseDate(promoDetails.start_date);
          let endDate = parseDate(promoDetails.end_date);
  
          // If dates are invalid after parsing, log an error
          if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            console.error("Invalid date format detected:", {
              start_date: promoDetails.start_date,
              end_date: promoDetails.end_date,
            });
            swal("Error", "Invalid date value in promo details", "error");
            return;
          }
  
          // Format dates to 'YYYY-MM-DD' (standard format for date inputs)
          const formattedStartDate = startDate.toISOString().split("T")[0];
          const formattedEndDate = endDate.toISOString().split("T")[0];
  
          // Log formatted dates
          console.log("Formatted Start Date:", formattedStartDate);
          console.log("Formatted End Date:", formattedEndDate);
  
          // Update state with formatted dates
          setEditpromo({
            ...promoDetails,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
          });
  
          console.log("Promo Details for Edit:", {
            ...promoDetails,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
          });
  
          setEditShowModal(true);
        } else {
          console.error("Promo with ID", addpromosId.id, "not found");
        }
      } else {
        console.error("Failed to fetch promo details");
      }
    } catch (error) {
      console.error("Error in date processing:", error.message);
      swal("Error", "An error occurred while processing promo details", "error");
    }
  };
  

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setImage(selectedFile);
  // };
  const [errorMessage, setErrorMessage] = useState("");
  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 140 * 1024; // 140KB in bytes

    if (selectedImage && selectedImage.size < maxSize) {
      setImage(selectedImage);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 140KB");
    }
  };
  // const handleFileInputChange = (event) => {
  //   const file = event.target.files[0];
  //   setImageFile(file);
  // };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const minSize = 140 * 1024; // 140KB in bytes
    if (file && file.size < minSize) {
      setImageFile(file);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 140KB");
    }
  };
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  // Function to format a Date object to 'dd-mm-yyyy' format
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Function to calculate the status based on formatted dates
  const calculateStatus = (startDate, endDate) => {
    // Parse and format the input dates
    const formattedStartDate = parseDate(startDate);
    const formattedEndDate = parseDate(endDate);

    // Get current date in 'dd-mm-yyyy' format
    const currentDate = new Date();
    const formattedCurrentDate = formatDate(currentDate);

    // Format the dates for comparison
    const formattedStartDateStr = formatDate(formattedStartDate);
    const formattedEndDateStr = formatDate(formattedEndDate);

    console.log({
      formattedStartDateStr,
      formattedCurrentDate,
      formattedEndDateStr,
    });

    // Compare dates as strings
    if (
      formattedCurrentDate >= formattedStartDateStr &&
      formattedCurrentDate <= formattedEndDateStr
    ) {
      return "Active";
    } else {
      return "Deactive";
    }
  };

  const handleUpdate = async () => {
    if (
      !editpromo.promo_code ||
      !editpromo.message ||
      !editpromo.start_date ||
      !editpromo.end_date
    ) {
      swal({
        title: "Warning",
        text: "Please fill in all fields and select an image.",
        icon: "warning",
        button: "OK",
      });
      return;
    }

    // Format the dates before sending
    const formattedStartDate = formatDate(new Date(editpromo.start_date));
    const formattedEndDate = formatDate(new Date(editpromo.end_date));

    const formData = new FormData();
    formData.append("promo_code", editpromo.promo_code);
    formData.append("message", editpromo.message);
    formData.append("start_date", formattedStartDate);
    formData.append("end_date", formattedEndDate);
    formData.append("no_user", editpromo.no_user);
    formData.append("min_orderamt", editpromo.min_orderamt);
    formData.append("dis_type", editpromo.dis_type);
    formData.append("max_distype", editpromo.max_distype);
    formData.append("rep_usage", editpromo.rep_usage);
    formData.append("no_of_rep_usage", editpromo.no_of_rep_usage);
    formData.append("image", imageFile);
    formData.append("current_image", editpromo.image);

    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/promocodeupdate.php?id=${editpromo.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        console.log("Promo updated successfully!");

        setAddPromo((prevAddPromo) =>
          prevAddPromo.map((promo) =>
            promo.id === editpromo.id ? { ...promo, ...editpromo } : promo
          )
        );

        setEditShowModal(false);
        GetData(); // Fetch the updated promo data
      } else {
        console.error("Failed to update promo. Status:", response.status);
      }
    } catch (error) {
      console.error("Error updating promo:", error);
    }
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setRepusage(value);
    if (value === "Not Allowed") {
      setNorepusage("");
    }
  };
  const [variant, setVariant] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(addpromo);
  }, [addpromo]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.promo_code || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, query]);

  return (
    <div className="main-container_1">
      <div className="Order-head">
        <h3 className="head-fon-mv">Manage Promo Code</h3>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            class="breadcrumb-header float-start float-lg-end"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" class="breadcrumb-item active">
                Manage Promo Code
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat">
        <div className="od_items_69"></div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
            </div>
          </div>
          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>
          </div> */}
          {/* {managepromoView.add === true ? (
            <button className="o_btn" onClick={() => setShowmodal(true)}>
              Add Promo Code
            </button>
          ) : (
            ""
          )} */}

          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "10px" }}
          >
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>

            <div style={{ padding: "10px", marginBottom: "6px" }}>
              {managepromoView.add === true ? (
                <button className="o_btn" onClick={() => setShowmodal(true)}>
                  Add Promo Code
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="table-container text-end ">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.No</th>

                  <th
                    onClick={() => handleSortByColumn("promo_code")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Promo Code
                    {sortColumn === "promo_code" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("message")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Message
                    {sortColumn === "message" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("start_date")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Start Date
                    {sortColumn === "start_date" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("end_date")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    End Date
                    {sortColumn === "end_date" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("no_user")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    No User
                    {sortColumn === "no_user" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("min_orderamt")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Minimum Order Amount
                    {sortColumn === "min_orderamt" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("dis_type")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Discount Type
                    {sortColumn === "dis_type" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("max_distype")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Max Discount Amount
                    {sortColumn === "max_distype" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>

                  <th
                    onClick={() => handleSortByColumn("rep_usage")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Repeat Usage
                    {sortColumn === "rep_usage" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>No Of Repeat Usage</th> */}
                  <th
                    onClick={() => handleSortByColumn("no_of_rep_usage")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    No Of Repeat Usage
                    {sortColumn === "no_of_rep_usage" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      ))}
                  </th>
                  <th style={{ textAlign: "center" }}>Image</th>
                  <th style={{ textAlign: "center" }}>Status</th>
                  {managepromoView.edit === true ||
                  managepromoView.delete === true ? (
                    <th style={{ textAlign: "center" }}>Action</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredCapacitie.length > 0 ? (
                  filteredCapacitie.map((addpromos, index) => (
                    <tr key={addpromos.id || index}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {addpromos.promo_code}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {addpromos.message}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {addpromos.start_date}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {addpromos.end_date}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {addpromos.no_user}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {addpromos.min_orderamt}
                      </td>
                      <td style={{ textAlign: "center" }}>
                      {addpromos.dis_type === "0" ? "Amount" : "Percentage"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {addpromos.max_distype}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {addpromos.rep_usage}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {addpromos.no_of_rep_usage}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <img
                          src={`https://arasurdigital.com/admin/image/promoimage/${addpromos.image}`}
                          width={40}
                          height={40}
                          alt="Promo"
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <span
                          className={`status ${
                            calculateStatus(
                              addpromos.start_date,
                              addpromos.end_date
                            ) === "Active"
                              ? "active-status"
                              : "deactive-status"
                          }`}
                        >
                          {calculateStatus(
                            addpromos.start_date,
                            addpromos.end_date
                          )}
                        </span>
                      </td>
                      {(managepromoView.edit || managepromoView.delete) && (
                        <td style={{ textAlign: "center" }}>
                          {managepromoView.edit && (
                            <button
                              className="btn-edit"
                              onClick={() => handleEdit(addpromos)}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </button>
                          )}
                          {managepromoView.delete && (
                            <button
                              className="btn-delete"
                              onClick={() => handelDelete(addpromos.id)}
                            >
                              <FaTrash />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="14" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">Add Promo Code</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>
        <ModalBody>
          <div className="mod_head">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="bold">
                    Promo Code <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Promo Code"
                    value={promocode}
                    onChange={(e) => setPromocode(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    Message <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    Start Date <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    value={startdate}
                    onChange={(e) => setStartdate(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    End Date <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    value={enddate}
                    onChange={(e) => setEnddate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="bold">
                    No.of.Users <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter no.of users"
                    value={nouser}
                    onChange={(e) => setNouser(e.target.value)}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    Minimum Order Amount <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Minimum  Amount"
                    value={minorderamt}
                    onChange={(e) => setOrderamnt(e.target.value)}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    Discount Type <i className="text-danger">*</i>
                  </label>
                  <select
                    className="form-select"
                    value={distype}
                    onChange={(e) => setDistype(e.target.value)}
                  >
                    <option value="">Select discount type</option>
                    <option value="0">Amount</option>
                    <option value="1">Percentage</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label className="bold">
                    Max Discount Amount <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Max  Amount"
                    value={maxdistype}
                    onChange={(e) => setMaxdistype(e.target.value)}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label className="bold">
              Repeat Usage <i className="text-danger">*</i>
            </label>
            <select
              className="form-select"
              placeholder="Repeat Usage"
              value={repusage}
              onChange={handleSelectChange}
            >
              <option value="Select">Select</option>
              <option value="Allowed">Allowed</option>
              <option value="Not Allowed">Not Allowed</option>
            </select>
          </div>
          {repusage === "Allowed" && (
            <div className="mb-3">
              <label className="bold">No Of Repeat Usage</label>
              <input
                className="form-control"
                placeholder="No Of Repeat Usage"
                value={norepusage}
                onChange={(e) => setNorepusage(e.target.value)}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          )}
          <p className="bold">
            Image <i className="text-danger">*</i>
          </p>
          <p>Please choose square image of less 140kb.</p>
          <input
            type="file"
            accept="image/*"
            className="form-control"
            onChange={handleFileChange}
          />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
        <header class="modal-header">
          <h5 class="modal-title">Add Promo Code</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={() => setEditShowModal(false)}
          >
            ×
          </button>
        </header>
        <ModalBody>
          <div className="mod_head">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="bold">
                    Promo Code <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Promo Code"
                    value={editpromo.promo_code}
                    onChange={(e) =>
                      setEditpromo({
                        ...editpromo,
                        promo_code: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    Message <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Message"
                    value={editpromo.message}
                    onChange={(e) =>
                      setEditpromo({
                        ...editpromo,
                        message: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    Start Date <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    value={editpromo.start_date || ""}
                    onChange={(e) =>
                      setEditpromo({
                        ...editpromo,
                        start_date: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    End Date <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    value={editpromo.end_date || ""}
                    onChange={(e) =>
                      setEditpromo({
                        ...editpromo,
                        end_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="bold">
                    No.of.Users <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter no.of users"
                    value={editpromo.no_user}
                    onChange={(e) =>
                      setEditpromo({
                        ...editpromo,
                        no_user: e.target.value,
                      })
                    }
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    Minimum Order Amount <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter min order amt"
                    value={editpromo.min_orderamt}
                    onChange={(e) =>
                      setEditpromo({
                        ...editpromo,
                        min_orderamt: e.target.value,
                      })
                    }
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label className="bold">
                    Discount Type <i className="text-danger">*</i>
                  </label>
                  <select
                    className="form-select"
                    value={editpromo.dis_type}
                    onChange={(e) =>
                      setEditpromo({
                        ...editpromo,
                        dis_type: e.target.value,
                      })
                    }
                  >
                    <option value="">Select discount type</option>
                    <option value="0">Amount</option>
                    <option value="1">Percentage</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label className="bold">
                    Max Discount Amount <i className="text-danger">*</i>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter max dis ant"
                    value={editpromo.max_distype}
                    onChange={(e) =>
                      setEditpromo({
                        ...editpromo,
                        max_distype: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label className="bold">
              Repeat Usage <i className="text-danger">*</i>
            </label>
            <select
              className="form-select"
              placeholder="Repeat Usage"
              value={editpromo.rep_usage}
              onChange={(e) =>
                setEditpromo({
                  ...editpromo,
                  rep_usage: e.target.value,
                })
              }
            >
              <option value="Select">Select</option>
              <option value="Allowed">Allowed</option>
              <option value="Not Allowed">Not Allowed</option>
            </select>
          </div>
          {editpromo.rep_usage === "Allowed" && (
            <div className="mb-3">
              <label className="bold">No Of Repeat Usage </label>
              <input
                className="form-control"
                placeholder="No Of Repeat Usage"
                value={editpromo.no_of_rep_usage}
                onChange={(e) =>
                  setEditpromo({
                    ...editpromo,
                    no_of_rep_usage: e.target.value,
                  })
                }
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          )}

          <p className="bold">
            Image <i className="text-danger">*</i>
          </p>
          <p>Please choose square image of less 140kb.</p>
          <input
            className="form-control"
            type="file"
            name="file2"
            id="myFile2"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              handleFileInputChange(e);
            }}
          />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <input
            className="form-control m-1"
            value={editpromo.image}
            name="current_image"
          />
          <span id="file-name" hidden>
            {filename}
          </span>
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={handleUpdate}>
            Update
          </Button>
          <Button variant="secondary" onClick={() => setEditShowModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Managepromo;
