import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Lottie from "react-lottie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Select from "react-select";
function Colourmaster() {
  const [colours, setColours] = useState([]);
  const [colourview, setColourview] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const [colourName, setColourName] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editcolourName, setEditcolourName] = useState({
    id: "",
    colour: "",
    category_id: "",
  });
  console.log("editcolourName", editcolourName);
  const [category, setCategory] = useState([]);
  const [categoryid, setCategoryid] = useState("");
  const [categoryid1, setCategoryid1] = useState("");
  // const categoryidsring = categoryid.join(', ');
  console.log("uji", categoryid1);

  const [selectedcategory, setSelectedcategory] = useState([]);
  const colourNameInputRef = useRef(null);
  const [EditshowModal, setEditShowModal] = useState(false);

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };
  const GetData = async () => {
    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/colour_master.php"
      );
      if (response.ok) {
        const data = await response.json();
        setColours(data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw error; // Propagate the error to the caller
    }
  };
  useEffect(() => {
    if (showModal) {
      colourNameInputRef.current.focus();
    }
    fetchCategories();
  }, [showModal]);
  useEffect(() => {
    setLoading(true);
    GetData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    const result = colours.filter((colour) => {
      const colourNameMatch =
        colour.colour &&
        colour.colour.toLowerCase().includes(query.toLowerCase());
      return colourNameMatch;
    });
    setFilter(result);
  }, [query, colours]);

  useEffect(() => {
    setCurrentPage(1);
  }, [query]);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };

  const handleEdit = async (colourId) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/colour_master.php?id=${colourId}`
      );
      if (response.ok) {
        const brands = await response.json();
        const categoryDetails = brands.find((item) => item.id === colourId);
        setColourName(categoryDetails.colour);
        setEditcolourName(categoryDetails); // Set both id and colour
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdate = async () => {
    if (!editcolourName.colour.trim()) {
      swal("warning", "Please enter colour name", "warning");
      return;
    }
    const formData = new FormData();
    formData.append("id", editcolourName.id);
    formData.append("colour", editcolourName.colour);
    formData.append("category_id", editcolourName.category_id); // ensure this is the correct value

    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/colour_masterupdate.php?id=${editcolourName.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        console.log("Colour updated successfully!");
        setColours((prevColours) =>
          prevColours.map((colour) =>
            colour.id === editcolourName.id ? editcolourName : colour
          )
        );
        setEditShowModal(false);
        setColourName("");
      } else {
        console.error("Failed to update colour");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCloseModal = () => {
    setColourName(""); // Clear the input field
    setCategoryid([]); // Clear category id field
    setSelectedcategory([]); // Clear selected category field
    setShowModal(false);
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
      console.log({ role111: role });
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    try {
      const res = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await res.json();
      setColourview(data.permission.ColourMasterView);
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const handleDelete = async (colourId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this colour master",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/colour_master.php?id=${colourId}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("Operating system deleted successfully!");
          GetData(); // Refresh the list after deleting
        } else {
          console.error("Failed to delete operating system");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleProductSelect = (selectedOptions) => {
    const productIds = selectedOptions.map((item) => item.value);
    const productvalue = selectedOptions.map((item) => item.label);
    console.log({ productIds: productvalue });
    setSelectedcategory(selectedOptions);
    setCategoryid(productvalue);
    setCategoryid1(productIds);

    console.log({ object: productIds });
  };

  const fetchCategories = () => {
    return fetch("https://arasurdigital.com/admin/categoriesmaster.php")
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((category) => ({
          value: category.id,
          label: category.category_name,
        }));
        setCategory(options);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  };
  const handleInsert = () => {
    if (!colourName.trim()) {
      swal("warning", "Please enter colour name", "warning");
      return;
    }
    // if (categoryid1.length === 0) {
    //   swal("Warning", "Please select at least one category", "warning");
    //   return;
    // }

    const formData = new FormData();
    formData.append("colour", colourName);
    //formData.append("category_id", categoryid1);
    fetch("https://arasurdigital.com/admin/colour_master.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData(); // Fetch the updated list of colours
        setShowModal(false); // Close the modal
        setColourName(""); // Clear the input field
        setCategoryid([]); // Clear category id field
        setSelectedcategory([]); // Clear selected category field
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCategoryLabels = (category_id) => {
    const categoryIds =
      typeof category_id === "string" ? category_id.split(",") : [category_id];
    return category
      .filter((item) => categoryIds.includes(item.value.toString()))
      .map((filteredItem) => filteredItem.label)
      .join(", ");
  };

  const getCategoryLabels1 = (categoryValue) => {
    if (!categoryValue) return [];

    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",")
        : categoryValue;
    return category.filter((item) =>
      categoryIds.includes(item.value.toString())
    );
  };

  // Function to handle selection changes
  const handleProductSelect1 = (selectedOptions) => {
    setEditcolourName((prev) => ({
      ...prev,
      category_id: selectedOptions.map((option) => option.value).join(","),
    }));
  };

  const [colour, setcolour] = useState([]);

  const handleSortByName = () => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.colour.localeCompare(b.colour);
      } else {
        return b.colour.localeCompare(a.colour);
      }
    });

    setcolour(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setcolour(colours);
  }, [colours]);
  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    const updatedFilteredCapacities = colour.filter((capacity) =>
      capacity.colour.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCapacities(updatedFilteredCapacities);
  }, [colour, query]);
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv"> Colour Master</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Colour Master
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat ">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                {/* <label>Entries</label> */}
              </div>
            </div>
            {/* <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
            {colourview.add === true ? (
              <button className="add-category-button" onClick={handleShowModal}>
                Add Colour Master
              </button>
            ) : (
              ""
            )} */}

            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "10px" }}
            >
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleInput}
                />
                <FaSearch className="search-icon" />
              </div>

              <div style={{ padding: "10px", marginBottom: "6px" }}>
                {colourview.add === true ? (
                  <button
                    className="add-category-button"
                    onClick={handleShowModal}
                  >
                    Add Colour Master
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ textAlign: "center" }}>Colour</th> */}
                    <th
                      onClick={() => handleSortByName()}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Colour
                      {sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      )}
                    </th>
                    {/* <th>Category Name</th> */}
                    {colourview.edit === true || colourview.delete === true ? (
                      <th style={{ textAlign: "center" }}>Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((colour, index) => (
                      <tr key={colour.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>{colour.colour}</td>
                        {/* <td>{getCategoryLabels(colour.category_id)}</td> */}
                        {(colourview.edit || colourview.delete) && (
                          <td style={{ textAlign: "center" }}>
                            {colourview.edit && (
                              <button
                                className="btn-edit"
                                onClick={() => handleEdit(colour.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {colourview.delete && (
                              <button
                                className="btn-delete"
                                onClick={() => handleDelete(colour.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={colourview.edit || colourview.delete ? 3 : 2}
                        style={{ textAlign: "center" }}
                      >
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>

          <Modal show={showModal} onHide={handleCloseModal}>
            <header class="modal-header">
              <h5 class="modal-title">Add Colour</h5>
              <button
                type="button"
                aria-label="Close"
                class="close"
                onClick={handleCloseModal}
              >
                ×
              </button>
            </header>

            <Modal.Body>
              <div>
                <label className="bold">
                  Colour Name <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Colour Name"
                  value={colourName}
                  ref={colourNameInputRef}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                      setColourName(inputValue);
                    }
                  }}
                />
                {/* <label className="bold">Category</label>
                <Select
                  id="categorySelect"
                  className="form-control"
                  placeholder="Select Category "
                  options={category}
                  value={selectedcategory}
                  isMulti
                  onChange={handleProductSelect}
                /> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleInsert}>
                Save
              </Button>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
            <header class="modal-header">
              <h5 class="modal-title">Edit Colour</h5>
              <button
                type="button"
                aria-label="Close"
                class="close"
                onClick={() => setEditShowModal(false)}
              >
                ×
              </button>
            </header>

            <Modal.Body>
              <div>
                <label className="bold">
                  Colour Name <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Colour Name"
                  value={editcolourName.colour}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                      setEditcolourName({
                        ...editcolourName,
                        colour: e.target.value,
                      })
                    }
                  }}
                 
                />
                {/* <label className="bold">Category</label>
                <Select
                  id="categorySelect"
                  className="form-control"
                  placeholder="Select Category"
                  options={category}
                  value={getCategoryLabels1(editcolourName.category_id)}
                  isMulti
                  onChange={handleProductSelect1}
                /> */}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="success" onClick={handleUpdate}>
                Update
              </Button>
              <Button
                variant="secondary"
                onClick={() => setEditShowModal(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Colourmaster;
