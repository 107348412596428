import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Lottie from "react-lottie";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
function Brands() {
  const [showModal, setShowModal] = useState(false);
  const Navigate = useNavigate();
  const [brand, setBrand] = useState([]);
  const [brandview, setBrandView] = useState("");
  const [id, setId] = useState("");
  const [brandname, setBrandname] = useState("");
  const [image, setImage] = useState(null);
  const [brandimage, setBrandimage] = useState("");
  const [status, setStatus] = useState("1");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("limited");
  const [errorMessage, setErrorMessage] = useState("");
  const [editShowModal, setEditShowModal] = useState(false);
  const [editBrand, setEditBrand] = useState({
    id: "",
    brand_name: "",
    brand_image: "",
    status: "",
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [search, setSearch] = useState(false);
  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };
  const filteredBrands =
    brand &&
    brand.filter(
      (brands) =>
        brands.brand_name &&
        brands.brand_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const sortedFilter = [...filteredBrands].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    setLoading(true);
    GetData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setFilename(editBrand.brand_image);
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setStatus(event.target.value);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleInsert = () => {
    // Check if any required fields are null or empty
    if (!brandname || !brandimage || !status) {
      swal("warning", "Please enter the details", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("brand_name", brandname);
    formData.append("brand_image", brandimage);
    formData.append("status", status);

    fetch("https://arasurdigital.com/admin/brand.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setShowModal(false);
    setBrandname("");
  };

  const GetData = async () => {
    const data = await fetch("https://arasurdigital.com/admin/brand.php");
    const response = await data.json();
    setBrand(response);
  };

  const handleEdit = async (brandsId) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/brand.php?id=${brandsId}`
      );
      if (response.ok) {
        const brands = await response.json();
        const categoryDetails = brands.find((item) => item.id === brandsId);
        setEditBrand(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (brandsId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this brand!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/brand.php?id=${brandsId}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          setBrand(brand.filter((brands) => brands.id !== brandsId));
          swal("Deleted!", "Brand has been deleted.", "success");
        } else {
          console.error("Failed to delete brand");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleFileChange = (e) => {
  //   const selectedImage = e.target.files[0];
  //   const maxSize = 20 * 1024 * 1024; // 20MB in bytes

  //   if (selectedImage && selectedImage.size <= maxSize) {
  //     setBrandimage(selectedImage);
  //     setErrorMessage("");
  //   } else {
  //     setErrorMessage("Please select a file smaller than 20MB");
  //   }
  // };

  // const handleFileInputChange = (event) => {
  //   const file = event.target.files[0];
  //   setImageFile(file);
  // };

  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const maxSize = 140 * 1024; // 140KB in bytes

    if (selectedImage && selectedImage.size < maxSize) {
      setBrandimage(selectedImage);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 140KB");
    }
  };
  // const handleFileInputChange = (event) => {
  //   const file = event.target.files[0];
  //   setImageFile(file);
  // };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const minSize = 140 * 1024; // 140KB in bytes
    if (file && file.size < minSize) {
      setImageFile(file);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a file smaller than 140KB");
    }
  };
  const handleUpdate = async () => {
    try {
      if (
        !editBrand.brand_name ||
        !editBrand.brand_image ||
        !editBrand.status
      ) {
        swal("warning", "Please enter the details", "warning");
        return;
      }
      const formData = new FormData();
      formData.append("id", editBrand.id);
      formData.append("brand_name", editBrand.brand_name);
      formData.append("brand_image", imageFile);
      formData.append("current_image", editBrand.brand_image);
      formData.append("status", editBrand.status);

      const response = await fetch(
        `https://arasurdigital.com/admin/brandupdate.php?id=${editBrand.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
     
        GetData();
        setBrand(
          brand.map((category) =>
            category.id === editBrand.id ? editBrand : brand
          )
        );
        setEditShowModal(false);
        setBrandimage("");
        setImageFile("");
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    GetData();
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");
  
      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
 
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setBrandView(data.permission.BrandsView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };
  const [sortOrder, setSortOrder] = useState("asc");
  const [filter, setFilteredBrand] = useState([]);
  useEffect(() => {
    setFilteredBrand(brand);
    setSearch(!search);
    // setOrderBrand(filter);
  }, [brand]);
  useEffect(() => {
    setOrder(brand);
  }, [brand]);
  // const [orderBrand, setOrderBrand] = useState([]);
  const [order, setOrder] = useState([]);
  const handleSortByName = () => {
    const sortedBrands = [...filter].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.brand_name.localeCompare(b.brand_name);
      } else {
        return b.brand_name.localeCompare(a.brand_name);
      }
    });

    setOrder(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    const updatedFilteredCapacities = order.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.brand_name || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [order, searchQuery]);
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Brands</h3>
          <div class="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              class="breadcrumb-header float-start float-lg-end"
            >
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" class="breadcrumb-item active">
                  Brands
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                {/* <label>Entries</label> */}
              </div>
            </div>
            {/* <div className="search-left">
              <div className="searchbar">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleSearchChange}
                />
                <FaSearch className="search-icon" />
              </div>
            </div>
            {brandview.add === true ? (
              <button
                className="add-category-button"
                onClick={() => setShowModal(true)}
              >
                Add Brands
              </button>
            ) : (
              ""
            )} */}

            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "10px" }}
            >
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={handleSearchChange}
                />
                <FaSearch className="search-icon" />
              </div>

              <div style={{ padding: "10px", marginBottom: "6px" }}>
                {brandview.add === true ? (
                  <button
                    className="add-category-button"
                    onClick={() => setShowModal(true)}
                  >
                    Add Brands
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    <th
                      onClick={() => handleSortByName()}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Name
                      {sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      )}
                    </th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    {(brandview.edit || brandview.delete) && (
                      <th style={{ textAlign: "center" }}>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredCapacitie.length > 0 ? (
                    filteredCapacitie.map((brand, index) => (
                      <tr key={brand.id}>
                        <td style={{ textAlign: "center" }}>
                          {startIndex + index + 1}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <img
                            src={`https://arasurdigital.com/admin/image/brands/${brand.brand_image}`}
                            alt={brand.brand_name}
                            width={40}
                            height={40}
                            style={{ margin: "10px" }}
                          />
                          {brand.brand_name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {brand.status === "1" ? (
                            <span className="active-item">Active</span>
                          ) : (
                            <span className="inactive-item">In-Active</span>
                          )}
                        </td>
                        {(brandview.edit || brandview.delete) && (
                          <td style={{ textAlign: "center" }}>
                            {brandview.edit && (
                              <button
                                className="btn-edit"
                                onClick={() => handleEdit(brand.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            {brandview.delete && (
                              <button
                                className="btn-delete"
                                onClick={() => handleDelete(brand.id)}
                              >
                                <FaTrash />
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={brandview.edit || brandview.delete ? 4 : 3}
                        style={{ textAlign: "center" }}
                      >
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Add Brand</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Name <i className="text-danger">*</i>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                value={brandname}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                    setBrandname(inputValue);
                  }
                }}
              />

              <label className="bold">
                Status <i className="text-danger">*</i>
              </label>
              <div className="form-switch formswitch d-flex align-items-center">
                <input
                  className={`form-check-input custom-switch w-100 ${
                    status === "1" ? "bg-success" : "bg-danger"
                  }`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={status === "1"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "1" : "0";
                    setStatus(newValue);
                  
                  }}
                />
                <label
                  htmlFor="flexSwitchCheckDefault"
                  className="status-label ms-2 mb-0"
                >
                  {status === "1" ? (
                    <span className="text-success w-20">Active</span>
                  ) : (
                    <span className="text-danger ">In-Active</span>
                  )}
                </label>
              </div>
              <label className="bold">
                Image <i className="text-danger">*</i>
              </label>
              <p>Please choose square image of less 140kb</p>
              <input
                type="file"
                accept="image/*"
                className="form-control"
                onChange={handleFileChange}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {image && (
                <img src={URL.createObjectURL(image)} alt="Uploaded Image" />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Update Brand</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <div>
              <label className="bold">
                Name <i className="text-danger">*</i>
              </label>
              <input
                className="form-control"
                placeholder="Enter Name"
                value={editBrand.brand_name}
                onChange={(e) => {
                  const inputValue1 = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(inputValue1)) {
                  setEditBrand({
                    ...editBrand,
                    brand_name: inputValue1,
                  });
                }
                }}
              />
              <label className="bold">
                Status <i className="text-danger">*</i>
              </label>
              <div className="form-switch formswitch d-flex align-items-center">
                <input
                  className={`form-check-input custom-switch w-100 ${
                    editBrand.status === "1" ? "bg-success" : "bg-danger"
                  }`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={editBrand.status === "1"}
                  onClick={() => {
                    setEditBrand({
                      ...editBrand,
                      status: editBrand.status === "1" ? "0" : "1",
                    });
                  }}
                />
                <label
                  htmlFor="flexSwitchCheckDefault"
                  className="status-label ms-2 mb-0"
                >
                  {editBrand.status === "1" ? (
                    <span className="text-success w-20">Active</span>
                  ) : (
                    <span className="text-danger ">In-Active</span>
                  )}
                </label>
              </div>
              <label className="bold">
                Image <i className="text-danger">*</i>
              </label>
              <p>Please choose square image of less 140kb</p>

              <input
                className="form-control mb-2 "
                type="file"
                name="file2"
                id="myFile2"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  handleFileInputChange(e);
                }}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <input
                className="form-control"
                value={editBrand.brand_image}
                name="current_image"
              />
              <span id="file-name" hidden>
                {filename}
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleUpdate()}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Brands;
