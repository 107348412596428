import React, { useState, useEffect } from "react";
import "./Managedelivery.css";
import Modal from "react-bootstrap/Modal";
import { LuRefreshCcw } from "react-icons/lu";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Lottie from "react-lottie";
function Managedelivery() {
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [filename, setFilename] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState({
    id: "",
    delivery_boy_name: "",
    mobile_no: "",
    email_id: "",
    password: "",
    image: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    setFilename(editUser.image);
  }, []);
  const [EditshowModal, setEditShowModal] = useState(false);
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/deliveryboy.php"
      );
      const data = await response.json();
      setDeliveryBoys(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/deliveryboy.php?id=${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setDeliveryBoys(
          deliveryBoys.filter((homesliders) => homesliders.id !== id)
        );
      } else {
        console.error("Failed to delete taxes");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCloseModal = () => {
    setEditShowModal(false);
  };

  const handleEdit = async (usersId) => {
    console.log("loh", usersId);
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/deliveryboy.php?id=${usersId}`
      );

      if (response.ok) {
        const category = await response.json();
        const categoryDetails = category.find((item) => item.id === usersId);
        // console.log("deteils", categoryDetails);

        setEditUser(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Failed to fetch category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const handleupdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", editUser.id);
      formData.append("delivery_boy_name", editUser.delivery_boy_name);
      formData.append("email", editUser.email_id);
      formData.append("mobile", editUser.mobile_no);
      formData.append("password", editUser.password);
      formData.append("image", imageFile);
      formData.append("current_image", editUser.image);

      const response = await fetch(
        `https://arasurdigital.com/admin/deliveryboyupdate.php?id=${editUser.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        console.log("Category updated successfully!");
        setDeliveryBoys(
          deliveryBoys.map((category) =>
            category.id === editUser.id ? editUser.id : deliveryBoys
          )
        );
        setEditShowModal(false);
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    fetchData();
  };

  return (
    <div className="main-container_1">
      <div className="Order-head">
        <h3>Delivery Boys</h3>
        <p>Dashboard / Delivery Boyss </p>
      </div>
      <div className="card-cat">

        <div className="form-md">
          <div className="p-2">
            <label>Per page</label>
            <select className="form-select">
              <option value="20">2</option>
              <option value="40">5</option>
              <option value="60">10</option>
              <option value="80">20</option>
              <option value="80">50</option>
            </select>
          </div>
          <div className="input-md">
            <label>Filter Deliver Boy By status</label>
            <input type="text" placeholder="All" className="form-control" />
          </div>
          <div className="input-md">
            <label>Search</label>
            <input type="text" placeholder="" className="form-control" />
          </div>
          <div className="od_items_69">

            <button className="o_btn">Add Delivery Boys</button>
          </div>
          <div className="input-md">
            <LuRefreshCcw className="refresh" onClick={fetchData} />
          </div>

        </div>
        <div className="table-container text-end ">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>O.ID</th>
                  <th style={{ textAlign: "center" }}>User</th>
                  <th style={{ textAlign: "center" }}>Mobile</th>
                  <th style={{ textAlign: "center" }}>Email</th>
                  <th style={{ textAlign: "center" }}>Password</th>
                  <th style={{ textAlign: "center" }}>Image</th>
                  <th style={{ textAlign: "center" }}>Edit</th>
                  <th style={{ textAlign: "center" }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {deliveryBoys.map((boy, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{boy.id}</td>
                    <td style={{ textAlign: "center" }}>{boy.delivery_boy_name}</td>
                    <td style={{ textAlign: "center" }}>{boy.mobile_no}</td>
                    <td style={{ textAlign: "center" }}>{boy.email_id}</td>
                    <td style={{ textAlign: "center" }}>{boy.password}</td>
                    <td>
                      <img
                        src={`https://arasurdigital.com/admin1/image/DeliveryBoyImage/${boy.image}`}
                        width={40}
                        height={40}
                      />
                    </td>
                    <td>
                      <button className="btn-edit">
                        <FaPen onClick={() => handleEdit(boy.id)} />
                      </button>
                    </td>
                    <td>
                      <button className="btn-delete">
                        <FaTrash onClick={() => handleDelete(boy.id)} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="d-flex justify-content-between">

          <div className="dataTables_paginate paging_simple_numbers p-3">

          </div>
        </div>
      </div>
      <ul className="pagination justify-content-end">
        <li className="page-item">
          <button className="page-link">Previous</button>
        </li>
        <li className="page-item">
          <button className="page-link"></button>
        </li>
        <li className="page-item">
          <button className="page-link">Next</button>
        </li>
      </ul>
      <Modal show={EditshowModal} onHide={() => setEditShowModal(false)}>
        <header class="modal-header">
          <h5 class="modal-title"> View Delivery Boysr</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={() => setEditShowModal(false)}
          >
            ×
          </button>
        </header>

        <Modal.Body>
          <div>
            <label>
              Username <i className="text-danger">*</i>
            </label>
            <input
              className="form-control"
              placeholder="Enter Username"
              value={editUser.delivery_boy_name}
              onChange={(e) =>
                setEditUser({
                  ...editUser,
                  delivery_boy_name: e.target.value,
                })
              }
            ></input>
            <label>
              Mobile_no <i className="text-danger">*</i>
            </label>
            <input
              className="form-control"
              placeholder="Enter Email"
              value={editUser.mobile_no}
              onChange={(e) =>
                setEditUser({
                  ...editUser,
                  mobile_no: e.target.value,
                })
              }
            ></input>
            <label>
              Email <i className="text-danger">*</i>
            </label>
            <input
              className="form-select"
              placeholder="Enter Role "
              value={editUser.email_id}
              onChange={(e) =>
                setEditUser({
                  ...editUser,
                  email_id: e.target.value,
                })
              }
            />
            <div className="form-group">
              <label htmlFor="password">
                Password <span className="text-danger text-xs">*</span>
              </label>
              <div className="input-group">
                <input
                  id="password"
                  placeholder="Password"
                  required
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  value={editUser.password}
                  onChange={(e) =>
                    setEditUser({
                      ...editUser,
                      password: e.target.value,
                    })
                  }
                />
                <button
                  type="button"
                  className="btn btn-primary font-bold"
                  onClick={togglePasswordVisibility}
                >
                  {/* <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /> */}
                </button>
              </div>
            </div>{" "}
            <p>Image</p>
            <p>
              Please choose square image of larger than 350px*350px & smaller
              than 550px*550px.
            </p>
            <input
              style={{ width: "80%" }}
              type="file"
              name="file2"
              id="myFile2"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => {
                handleFileInputChange(e);
              }}
            />
            <input
              value={editUser.image}
              type=" hidden "
              name="current_image"
            />
            <span id="file-name">{filename}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleupdate}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Managedelivery;
